import React, { useEffect, useState } from "react";
import classes from "./CustomerView.module.css";
import Dropdown from "react-bootstrap/Dropdown";
import CustomerAvatar from "../../../assets/customer-view-asstes/avatar.jpg";
import CodeIcon from "../../../assets/customer-view-asstes/code.png";
import TypeIcon from "../../../assets/customer-view-asstes/type.png";
import AlternateIcon from "../../../assets/customer-view-asstes/alternate.png";
import TelephoneIcon from "../../../assets/customer-view-asstes/telephone.png";
import GstIcon from "../../../assets/customer-view-asstes/gst.png";
import PanIcon from "../../../assets/customer-view-asstes/pan.png";
import CountryIcon from "../../../assets/customer-view-asstes/country.png";
import StateIcon from "../../../assets/customer-view-asstes/state.png";
import CityIcon from "../../../assets/customer-view-asstes/city.png";
import ZipcodeIcon from "../../../assets/customer-view-asstes/zipcode.png";
import contactPersonIcon from "../../../assets/customer-view-asstes/contact-person.svg";
import CheckIcon from "../../../assets/customer-view-asstes/check.png";
import editIcon from "../../../assets/customer-view-asstes/edit-regular-24.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// COMPONENT
import Box from "../../../shared/structures/Box";
import Input from "../../../shared/inputs/Input";
import EditModal from "../../../shared/modal/EditModal";
import Form from "../../../shared/form/FormStructure";
import SmallBox from "../../../shared/structures/SmallBox";
import ViewDomain from "./viewDomain/ViewDomain";
import ViewHosting from "./viewHosting/ViewHosting";
import ViewEmail from "./viewEmail/ViewEmail";
import ViewSsl from "./viewSsl/ViewSsl";
import DeleteBtn from "../../../shared/buttons/deleteButton/DeleteBtn";

const Customerview = () => {
  const [customerData, setCustomerData] = useState([]);
  const [contactPersonData, setContactPersonData] = useState([]);
  const [isDetailSelected, setIsDetailSelected] = useState(true);
  const [isDomainSelected, setIsDomainSelected] = useState(true);
  const [isHostingSelected, setIsHostingSelected] = useState(false);
  const [isEmailSelected, setIsEmailSelected] = useState(false);
  const [isSslSelected, setIsSslSelected] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedContactPersonData, setSelectedContactPersonData] = useState(
    []
  );
  const [allContPerson, setAllContPerson] = useState({
    contact_person: "",
    designation: "",
    contact_person_mobile: "",
    contact_person_email: "",
  });
  const navigate = useNavigate();
  const location = useLocation();

  const contPersonChangeHandler = (e) => {
    setAllContPerson({
      ...allContPerson,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const fetchCustomerData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/customer/${location?.state?.code}`
      );
      setCustomerData(res.data);
    };
    fetchCustomerData();
  }, []);

  useEffect(() => {
    const fetchContactPerson = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/contactperson/${location?.state?.code}`
      );
      setContactPersonData(res.data);
    };
    if (customerData.customertype === "company") {
      fetchContactPerson();
    }
  }, [modalShow, selectedContactPersonData]);

  const submitContactPerson = async (e) => {
    e.preventDefault();
    await fetch(`${process.env.REACT_APP_BASE_URL}/contactperson`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        code: customerData.code,
        contact_person: allContPerson.contact_person,
        designation: allContPerson.designation,
        contact_person_mobile: allContPerson.contact_person_mobile,
        contact_person_email: allContPerson.contact_person_email,
      }),
    });
    setAllContPerson({
      contact_person: "",
      designation: "",
      contact_person_mobile: "",
      contact_person_email: "",
    });
    setModalShow(false);
  };

  const deleteHandler = () => {
    navigate("/customerlist");
  };
  const contactDeleteHandler = () => {
    setSelectedContactPersonData([]);
  };

  const resetHandler = () => {
    setAllContPerson({
      contact_person: "",
      designation: "",
      contact_person_mobile: "",
      contact_person_email: "",
    });
  };


  const domainViewHandler = () => {
    setIsDomainSelected(true);
    setIsDetailSelected(false);
    setIsHostingSelected(false);
    setIsEmailSelected(false);
    setIsSslSelected(false);
  };
  const hostingViewHandler = () => {
    setIsHostingSelected(true);
    setIsDomainSelected(false);
    setIsDetailSelected(false);
    setIsEmailSelected(false);
    setIsSslSelected(false);
  };
  const emailViewHandler = () => {
    setIsEmailSelected(true);
    setIsHostingSelected(false);
    setIsDomainSelected(false);
    setIsDetailSelected(false);
    setIsSslSelected(false);
  };
  const sslViewHandler = () => {
    setIsSslSelected(true);
    setIsHostingSelected(false);
    setIsDomainSelected(false);
    setIsDetailSelected(false);
    setIsEmailSelected(false);
  };

  const viewContactpersonHandler = (data) => {
    setSelectedContactPersonData(data);
  };

  const addContactPerson = () => {
    setModalShow(true);
  };
  const astric = <sup>*</sup>;

  let title = () => {
    if (isDetailSelected) {
      return "Customer Detail";
    } else if (isDomainSelected) {
      return "Domain Details";
    } else if (isHostingSelected) {
      return "Hosting Selected";
    } else if (isEmailSelected) {
      return "Email Details";
    } else if (isSslSelected) {
      return "SSL Details";
    } else {
      return "Customer Detail";
    }
  };

  return (
    <>
      <Box
        iconName="person-outline"
        boxTitle="View Customer"
        info={title()}
        parentBreadCrumb="dashboard"
        childBreadCrumb="/customerlist"
      >
        <div className={classes[selectedContactPersonData.length === 0?"customer-detail":"customer-detail-with-contact-person"]}>
          <div className={classes["customer-box1"]}>
            <img
              className={classes["avatar-img"]}
              src={CustomerAvatar}
              alt="customer-avatar"
            />
            <h2>{customerData.customer_name}</h2>
            <h6>{customerData.email}</h6>
            <h5>{customerData.mobile}</h5>
            {customerData.customertype === "company" && (
              <div className={classes["contact-person-container"]}>
                <Dropdown className={classes["drop-down"]}>
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    Contact Person
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {contactPersonData.length === 0 ? (
                      <Dropdown.Item>No Option</Dropdown.Item>
                    ) : (
                      contactPersonData.map((data) => {
                        return (
                          <Dropdown.Item
                            onClick={() => viewContactpersonHandler(data)}
                            key={data.id}
                          >
                            {data.contact_person}
                          </Dropdown.Item>
                        );
                      })
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <span>
                  <button
                    onClick={addContactPerson}
                    className={classes["add-contact-btn"]}
                  >
                    +
                  </button>
                </span>
              </div>
            )}
            <DeleteBtn
              className={classes["delete-btn"]}
              ondelete={deleteHandler}
              masterRoutes={"customer"}
              id={customerData.code}
              deletingItem={"Customer"}
            />
          </div>
          <div className={classes["customer-box2"]}>
            <div className={classes["sm-bx"]}>
              <div>
                <img src={CodeIcon} alt="code-icon" />
              </div>
              <div>
                <span>Code</span>
                <span>{customerData.code}</span>
              </div>
            </div>
            <div className={classes["sm-bx"]}>
              <div>
                <img src={TypeIcon} alt="code-icon" />
              </div>
              <div>
                <span>Customer Type</span>
                <span>{customerData.customertype}</span>
              </div>
            </div>
            <div className={classes["sm-bx"]}>
              <div>
                <img src={AlternateIcon} alt="code-icon" />
              </div>
              <div>
                <span>Alt No.</span>
                {customerData.alternate_no ? (
                  <span>{customerData.alternate_no}</span>
                ) : (
                  <span>-- --</span>
                )}
              </div>
            </div>
            <div className={classes["sm-bx"]}>
              <div>
                <img src={TelephoneIcon} alt="code-icon" />
              </div>
              <div>
                <span>Tel No.</span>
                {customerData.tel_no ? (
                  <span>{customerData.tel_no}</span>
                ) : (
                  <span>-- --</span>
                )}
              </div>
            </div>
            <div className={classes["sm-bx"]}>
              <div>
                <img src={GstIcon} alt="code-icon" />
              </div>
              <div>
                <span>Gst No.</span>
                {customerData.gst_no ? (
                  <span>{customerData.gst_no}</span>
                ) : (
                  <span>-- --</span>
                )}
              </div>
            </div>
          </div>

          <div className={classes["customer-box3"]}>
            <div className={classes["sm-bx"]}>
              <div>
                <img src={PanIcon} alt="code-icon" />
              </div>
              <div>
                <span>Pan No.</span>
                {customerData.pan_no ? (
                  <span>{customerData.pan_no}</span>
                ) : (
                  <span>-- --</span>
                )}
              </div>
            </div>
            <div className={classes["sm-bx"]}>
              <div>
                <img src={CountryIcon} alt="code-icon" />
              </div>
              <div>
                <span>Country</span>
                <span>{customerData.country}</span>
              </div>
            </div>
            <div className={classes["sm-bx"]}>
              <div>
                <img src={StateIcon} alt="code-icon" />
              </div>
              <div>
                <span>State</span>
                <span>{customerData.state}</span>
              </div>
            </div>
            <div className={classes["sm-bx"]}>
              <div>
                <img src={ZipcodeIcon} alt="code-icon" />
              </div>
              <div>
                <span>Zipcode</span>
                <span>{customerData.zip_code}</span>
              </div>
            </div>
            <div className={classes["sm-bx"]}>
              <div>
                <img src={CityIcon} alt="code-icon" />
              </div>
              <div>
                <span>City</span>
                <span>{customerData.city}</span>
              </div>
            </div>
          </div>
          {selectedContactPersonData.length === 0 ? (
            ""
          ) : (
            <div className={classes["customer-box4"]}>
              <img
                className={classes["contact-img"]}
                src={contactPersonIcon}
                alt="customer-avatar"
              />
              <h2>{selectedContactPersonData.contact_person}</h2>
              <h4>{selectedContactPersonData.designation}</h4>
              <h6>{selectedContactPersonData.contact_person_email}</h6>
              <h5>{selectedContactPersonData.contact_person_mobile}</h5>
              <DeleteBtn
                className={classes["delete-btn"]}
                ondelete={contactDeleteHandler}
                masterRoutes={"contactperson"}
                id={selectedContactPersonData.id}
                deletingItem={"Contact Person"}
              />
            </div>
          )}
        </div>
        {/* ///////////////////// address */}
        <div className={classes["customer-box5"]}>
          <div className={classes["sm-bx"]}>
            <div>
              <img src={CityIcon} alt="code-icon" />
            </div>
            <div>
              <span>Address 1</span>
              <span>{customerData.address1}</span>
            </div>
          </div>
          <div className={classes["sm-bx"]}>
            <div>
              <img src={CityIcon} alt="code-icon" />
            </div>
            <div>
              <span>Address 2</span>
              <span>{customerData.address2}</span>
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              navigate("/customerlist/customerview/customeredit", {
                state: { data: customerData },
              });
            }}
            className={classes["customer-edit-btn"]}
          >
            <span>
              <img src={editIcon} alt="edit-icon" />
            </span>
            <span>Customer Details</span>
          </button>
        </div>

        <div className={classes["select-service"]}>
          <span
            className={classes[isDomainSelected ? "selected" : "not-selected"]}
            onClick={domainViewHandler}
          >
            {isDomainSelected ? (
              <>
                Domain
                <img
                  className={classes["check-icon"]}
                  src={CheckIcon}
                  alt="icon"
                />
              </>
            ) : (
              "Domain"
            )}
          </span>
          <span
            className={classes[isHostingSelected ? "selected" : "not-selected"]}
            onClick={hostingViewHandler}
          >
            {isHostingSelected ? (
              <>
                Hosting
                <img
                  className={classes["check-icon"]}
                  src={CheckIcon}
                  alt="icon"
                />
              </>
            ) : (
              "Hosting"
            )}
          </span>
          <span
            className={classes[isEmailSelected ? "selected" : "not-selected"]}
            onClick={emailViewHandler}
          >
            {isEmailSelected ? (
              <>
                Email
                <img
                  className={classes["check-icon"]}
                  src={CheckIcon}
                  alt="icon"
                />
              </>
            ) : (
              " Email"
            )}
          </span>
          <span
            className={classes[isSslSelected ? "selected" : "not-selected"]}
            onClick={sslViewHandler}
          >
            {isSslSelected ? (
              <>
                SSL
                <img
                  className={classes["check-icon"]}
                  src={CheckIcon}
                  alt="icon"
                />
              </>
            ) : (
              "SSL"
            )}
          </span>
        </div>
        {isDomainSelected && <ViewDomain code={customerData.code} />}
        {isHostingSelected && <ViewHosting code={customerData.code} />}
        {isEmailSelected && <ViewEmail code={customerData.code} />}
        {isSslSelected && <ViewSsl code={customerData.code} />}

        {modalShow && (
          <EditModal
            show={modalShow}
            title={"Add Contact Person"}
            onHide={() => {
              setModalShow(false);
            }}
          >
            <Form
              onSubmit={submitContactPerson}
              onreset={resetHandler}
              btn1Title="Reset"
              btn2Title="+ Contact Person"
            >
              <SmallBox>
                <Input
                  label="Name"
                  type="text"
                  name="contact_person"
                  placeholder="Enter Name"
                  required="required"
                  onChange={contPersonChangeHandler}
                  value={allContPerson.contact_person}
                  astric={astric}
                />
                <Input
                  label="Designation"
                  type="text"
                  name="designation"
                  placeholder="Enter Designation"
                  required="required"
                  onChange={contPersonChangeHandler}
                  value={allContPerson.designation}
                  astric={astric}
                />
              </SmallBox>
              <SmallBox>
                <Input
                  label="Mobile"
                  type="number"
                  name="contact_person_mobile"
                  onChange={contPersonChangeHandler}
                  value={allContPerson.contact_person_mobile}
                  placeholder="Enter Mobile no."
                  required="required"
                  astric={astric}
                />
                <Input
                  label="Email"
                  type="email"
                  name="contact_person_email"
                  onChange={contPersonChangeHandler}
                  value={allContPerson.contact_person_email}
                  placeholder="Enter Email"
                  required="required"
                  astric={astric}
                />
              </SmallBox>
            </Form>
          </EditModal>
        )}
      </Box>
    </>
  );
};

export default Customerview;
