import React from "react";
import classes from "./EditBtn.module.css";

const EditBtn = (props) => {

  return (
    <button className={classes['edit-btn']}>
      <ion-icon onClick={props.onClick} name="create-outline"></ion-icon>
    </button>
  );
};

export default EditBtn;
