import React, { useState, useEffect } from "react";
import classes from "./UserList.module.css";
import axios from "axios";

import Form from "../../../shared/form/FormStructure";
import SmallBox from "../../../shared/structures/SmallBox";
import Input from "../../../shared/inputs/Input";
import DeleteBtn from "../../../shared/buttons/deleteButton/DeleteBtn";
import EditBtn from "../../../shared/buttons/editButton/EditBtn";
import EditModal from "../../../shared/modal/EditModal";
import Toast from "../../toast/toast";
import { toast } from "react-toastify";

const UserList = () => {
  const [tableData, setTableData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [allEditedUser, setAllEditedUser] = useState({
    editName: "",
    editUsername: "",
    editEmail: "",
    editRole: "",
    editBranchName: "",
    editPassword: "",
  });
  const editUserChangeHandler = (e) => {
    setAllEditedUser({
      ...allEditedUser,
      [e.target.name]: e.target.value,
    });
  };
  let i = 1;
  const astric = <sup>*</sup>;

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/user`);
        setTableData(res.data);
      } catch (error) {
        throw new Error("something went wrong", error);
      }
    };
    fetchTableData();
  }, [reRender]);
  const updateHandler = async (e) => {
    e.preventDefault();
    await axios.put(
      `${process.env.REACT_APP_BASE_URL}/user/${editedData[0].user_id}`,
      {
        Name: allEditedUser.editName.toLowerCase(),
        username: allEditedUser.editUsername,
        email: allEditedUser.editEmail,
        branch_name: allEditedUser.editBranchName.toLowerCase(),
        role: allEditedUser.editRole.toLowerCase(),
        password: allEditedUser.editPassword,
      }
    );
    setModalShow(false);
    toast.success("User updated successfully");
    setReRender(!reRender);
    setAllEditedUser({
      editName: "",
      editUsername: "",
      editEmail: "",
      editBranchName: "",
      editRole: "",
      editPassword: "",
    });
  };
  const resetHandler = () => {
    setAllEditedUser({
      editName: "",
      editUsername: "",
      editEmail: "",
      editBranchName: "",
      editRole: "",
      editPassword: "",
    });
  };
  const deleteHandler = () => {
    toast.error("User Deleted Successfully.")
    setReRender(!reRender);
  };
  const editHandler = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user/${id}`
      );
      setEditedData(res.data);
      setAllEditedUser({
        editName: res.data[0].name,
        editUsername: res.data[0].user_name,
        editEmail: res.data[0].user_email,
        editBranchName: res.data[0].branch_name,
        editRole: res.data[0].role,
        editPassword: res.data[0].user_password,
      });
    } catch (error) {
      throw new Error("something went wrong", error);
    }
    setModalShow(true);
  };

  return (
    <table className={classes["table"]}>
      <thead className={classes.thead}>
        <tr>
          <th>Sr.no</th>
          <th>Name</th>
          <th>UserName</th>
          <th>Email</th>
          <th>Branch Name</th>
          <th>Role</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody className={classes.tbody}>
        {tableData.map((data) => (
          <tr className={classes.trow} key={data.user_id}>
            <td>{i++}</td>
            <td>{data.name}</td>
            <td>{data.user_name}</td>
            <td>{data.user_email}</td>
            <td>{data.branch_name}</td>
            <td>{data.role}</td>
            <td>
              <EditBtn
                onClick={() => {
                  editHandler(data.user_id);
                }}
              />
              {modalShow && (
                <EditModal
                  show={modalShow}
                  title={"User Edit"}
                  onHide={() => {
                    setModalShow(false);
                  }}
                >
                  <Form
                    onSubmit={updateHandler}
                    onreset={resetHandler}
                    btn1Title="Reset"
                    btn2Title="Save"
                  >
                    <SmallBox>
                      <Input
                        label="Name"
                        type="text"
                        name="editName"
                        placeholder="Enter Name"
                        onChange={editUserChangeHandler}
                        required="required"
                        value={allEditedUser.editName}
                        astric={astric}
                      />
                      <Input
                        label="User Name"
                        type="text"
                        name="editUsername"
                        placeholder="Enter UserName"
                        onChange={editUserChangeHandler}
                        required="required"
                        value={allEditedUser.editUsername}
                        astric={astric}
                      />
                    </SmallBox>
                    <SmallBox>
                      <Input
                        label="Email"
                        type="email"
                        name="editEmail"
                        placeholder="Enter Email"
                        onChange={editUserChangeHandler}
                        required="required"
                        value={allEditedUser.editEmail}
                        astric={astric}
                      />
                      <Input
                        label="Branch Name"
                        type="text"
                        name="editBranchName"
                        placeholder="Enter Branch Name"
                        onChange={editUserChangeHandler}
                        required="required"
                        value={allEditedUser.editBranchName}
                        astric={astric}
                      />
                    </SmallBox>
                    <SmallBox>
                      <div className={classes["inp-box"]}>
                        <label className={classes.label}>Role</label>
                        <sup>*</sup>
                        <select
                          onChange={editUserChangeHandler}
                          value={allEditedUser.allEditedUser}
                          className={classes["drop-down"]}
                          name="role"
                          required
                        >
                          <option value="--select--">--select--</option>
                          <option value="admin">Admin</option>
                          <option value="visitor">Visitor</option>
                        </select>
                      </div>
                      <Input
                        label="Password"
                        type="text"
                        name="editPassword"
                        placeholder="Enter Password"
                        onChange={editUserChangeHandler}
                        required="required"
                        value={allEditedUser.editPassword}
                        astric={astric}
                      />
                    </SmallBox>
                  </Form>
                </EditModal>
              )}
              <DeleteBtn
                id={data.user_id}
                masterRoutes={"user"}
                ondelete={deleteHandler}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <Toast/>
    </table>
  );
};

export default UserList;
