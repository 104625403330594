import React from "react";
import classes from "./Input.module.css";
import Form from "react-bootstrap/Form";

const Input = ({
  label,
  type,
  placeholder,
  value,
  astric,
  onChange,
  name,
  required,
  className,
  readOnly,
}) => {
  return (
    <div className={classes["inp-box"]}>  
      <Form.Label className='no-space-break'>
        {label}
        {astric}
      </Form.Label>
      <Form.Control
        onChange={onChange}
        name={name}
        className={classes[className]}
        type={type}
        value={value}
        placeholder={placeholder}
        required={required}
        readOnly={readOnly}
      />
     
    </div>
  );
};

export default Input;
