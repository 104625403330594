import React, { useState, useEffect } from "react";
import classes from "./ViewProject.module.css";
import Box from "../../../shared/structures/Box";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import editModuleIcon from "../../../assets/customer-view-asstes/edit-icon.png";
import DeleteBtn from "../../../shared/buttons/deleteButton/DeleteBtn";
import Toast from "../../toast/toast"
import { toast } from "react-toastify";

const ViewProject = () => {
  const [isEditingProject, setIsEditingProject] = useState(false);
  const [isEditingModule, setIsEditingModule] = useState(false);
  const [allProjectVal, setAllProjectVal] = useState([]);
  const [allModuleVal, setAllModuleVal] = useState([]);
  const [projectInfo, setProjectInfo] = useState([]);
  const [moduleInfo, setModuleInfo] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjectData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/project/${location?.state?.project_id}`
      );
      setModuleInfo(res.data[1]);
      setProjectInfo(res.data[0][0]);
      setAllModuleVal(res.data[1]);
      console.log(res.data[1]);
    };
    fetchProjectData();
  }, [refetch]);

  const projectChangeHandler = (e) => {
    setAllProjectVal({
      ...allProjectVal,
      [e.target.name]: e.target.value,
    });
  };
  const moduleChangeHandler = (e, index) => {
    const newArr = [...allModuleVal];
    newArr[index] = {
      ...newArr[index],
      [e.target.name]: e.target.value,
    };
    setAllModuleVal(newArr);
  };

  const projectEditHandler = () => {
    setIsEditingProject(true);
    setAllProjectVal({
      ...projectInfo,
      start_date: projectInfo.start_date.split("-").reverse().join("-"),
      end_date: projectInfo.end_date.split("-").reverse().join("-"),
    });
    console.log(allProjectVal);
  };

  const moduleEditHandler = (id, index) => {
    setIsEditingModule(true);
    setCurrentId(id);
    const newArr = [...allModuleVal];
    newArr[index] = {
      ...newArr[index],
      start_date: moduleInfo[index].start_date.split("-").reverse().join("-"),
      end_date: moduleInfo[index].end_date.split("-").reverse().join("-"),
    };

    setAllModuleVal(newArr);
  };

  const submitProjectHandler = async (e) => {
    e.preventDefault();
    await axios.put(
      `${process.env.REACT_APP_BASE_URL}/project/${allProjectVal.project_id}`,
      {
        ...allProjectVal,
      }
    );
    toast.success("Project update successfully.")
    setRefetch(!refetch);
    setIsEditingProject(false);
  };

  const submitModuleHandler = async (id, index) => {
    await axios.put(`${process.env.REACT_APP_BASE_URL}/projectmodule/${id}`, {
      ...allModuleVal[index],
    });
    toast.success("Module updated successfully.")
    setRefetch(!refetch);
    setIsEditingModule(false);
    setCurrentId(false);
  };

  const deleteHandler = () => {
    toast.error("Project deleted successfully")
    navigate("/projectlist");
  };

  const moduleDeleteHandler = () => {
    toast.error("Module deleted successfully")
    setRefetch((prev) => !prev);
  };
  return (
    <Box
      iconName="person-outline"
      boxTitle="View Project"
      info="Project Details"
      parentBreadCrumb="dashboard"
      childBreadCrumb="/projectlist"
    >
      <form className={classes["project"]} onSubmit={submitProjectHandler}>
        <div className={classes["project-detail-container"]}>
          <div className={classes["project-detail-box"]}>
            <span className={classes["customer-name"]}>
              Customer Name :{projectInfo.code} {projectInfo.customer_name}
            </span>
            <div className={classes["project-details"]}>
              <div>
                <span>Project Name</span>
                {isEditingProject ? (
                  <span>
                    <input
                      type="text"
                      name="project_name"
                      onChange={projectChangeHandler}
                      value={allProjectVal.project_name}
                      className={classes.inp}
                      required
                    />
                  </span>
                ) : (
                  <span>{projectInfo.project_name}</span>
                )}
              </div>
              <div>
                <span>Project Type</span>
                {isEditingProject ? (
                  <span>
                    <input
                      type="text"
                      name="project_type"
                      onChange={projectChangeHandler}
                      value={allProjectVal.project_type}
                      className={classes.inp}
                      required
                    />
                  </span>
                ) : (
                  <span>{projectInfo.project_type}</span>
                )}
              </div>
              <div>
                <span>Start Date</span>
                {isEditingProject ? (
                  <span>
                    <input
                      type="date"
                      name="start_date"
                      onChange={projectChangeHandler}
                      value={allProjectVal.start_date}
                      className={classes.inp}
                      required
                    />
                  </span>
                ) : (
                  <span>{projectInfo.start_date}</span>
                )}
              </div>
              <div>
                <span>End Date</span>
                {isEditingProject ? (
                  <span>
                    <input
                      type="date"
                      name="end_date"
                      onChange={projectChangeHandler}
                      value={allProjectVal.end_date}
                      className={classes.inp}
                      required
                    />
                  </span>
                ) : (
                  <span>{projectInfo.end_date}</span>
                )}
              </div>
              <div>
                <span>Amount</span>
                {isEditingProject ? (
                  <span>
                    <input
                      type="number"
                      name="amount"
                      onChange={projectChangeHandler}
                      value={allProjectVal.amount}
                      className={classes.inp}
                      required
                    />
                  </span>
                ) : (
                  <span>{projectInfo.amount}</span>
                )}
              </div>
            </div>
          </div>
          <div className={classes["project-description-container"]}>
            <span>Project Description</span>
            <div className={classes["project-description"]}>
              {isEditingProject ? (
                <textarea
                  className={classes["text-area"]}
                  name="project_description"
                  onChange={projectChangeHandler}
                  placeholder="Enter Project Description"
                  value={allProjectVal.project_description}
                  rows="8"
                />
              ) : (
                <>{projectInfo.project_description}</>
              )}
            </div>
          </div>
        </div>
        <div className={classes["project-btn-container"]}>
          {isEditingProject ? (
            <div className={classes["edit-btn-container"]}>
              <button
                onClick={() => setIsEditingProject(false)}
                className={classes["cancel-btn"]}
              >
                Cancel
              </button>
              <button type="submit" className={classes["submit-btn"]}>
                Save Changes
              </button>
            </div>
          ) : (
            <span>
              <div>
                <DeleteBtn
                  className={classes["delete-btn"]}
                  ondelete={deleteHandler}
                  masterRoutes={"project"}
                  id={location?.state?.project_id}
                  deletingItem={"Project"}
                />
              </div>

              <div>
                <button
                  type="button"
                  onClick={projectEditHandler}
                  className={classes["project-edit-btn"]}
                >
                  <span>
                    <img src={editModuleIcon} alt="edit-icon" />
                  </span>
                </button>
              </div>
            </span>
          )}
        </div>
      </form>

      <div className={classes["module-detail-container"]}>
        {moduleInfo.map((data, index) => {
          return (
            <div key={data.module_id}>
              <div className={classes["module-name"]}>
                <span>Module Name : </span>
                {isEditingModule && currentId === data.module_id ? (
                  <input
                    type="text"
                    name="module_name"
                    onChange={(e) => moduleChangeHandler(e, index)}
                    value={allModuleVal[index].module_name}
                    className={classes["module-inp"]}
                    required
                  />
                ) : (
                  <span> {data.module_name}</span>
                )}
              </div>
              <div className={classes["module-description-box"]}>
                <div
                  className={
                    classes[
                      isEditingModule && currentId === data.module_id
                        ? "module-container-edit"
                        : "module-container"
                    ]
                  }
                >
                  <div className={classes["module-details"]}>
                    <div>
                      <span>Amount :</span>
                      {isEditingModule && currentId === data.module_id ? (
                        <input
                          type="number"
                          name="amount"
                          onChange={(e) => moduleChangeHandler(e, index)}
                          value={allModuleVal[index].amount}
                          className={classes.inp}
                          required
                        />
                      ) : (
                        <>{data.amount === "0" ? " Included" : data.amount}</>
                      )}
                    </div>
                    <div>
                      <span>Start Date :</span>
                      {isEditingModule && currentId === data.module_id ? (
                        <input
                          type="date"
                          name="start_date"
                          onChange={(e) => moduleChangeHandler(e, index)}
                          value={allModuleVal[index].start_date}
                          className={classes.inp}
                          required
                        />
                      ) : (
                        <>
                          {data.start_date === projectInfo.start_date
                            ? " Included"
                            : data.start_date}
                        </>
                      )}
                    </div>
                    <div>
                      <span>End Date :</span>
                      {isEditingModule && currentId === data.module_id ? (
                        <input
                          type="date"
                          name="end_date"
                          onChange={(e) => moduleChangeHandler(e, index)}
                          value={allModuleVal[index].end_date}
                          className={classes.inp}
                          required
                        />
                      ) : (
                        <>
                          {data.end_date === projectInfo.end_date
                            ? " Included"
                            : data.end_date}
                        </>
                      )}
                    </div>
                  </div>
                  <div className={classes["module-description"]}>
                    {isEditingModule && currentId === data.module_id ? (
                      <textarea
                        className={classes["text-area"]}
                        name="module_description"
                        placeholder="Enter Project Description"
                        onChange={(e) => moduleChangeHandler(e, index)}
                        value={allModuleVal[index].module_description}
                        rows="7"
                      />
                    ) : (
                      <>{data.module_description}</>
                    )}
                  </div>
                </div>

                {isEditingModule && currentId === data.module_id ? (
                  <div className={classes["edit-modulebtn-container"]}>
                    <button
                      className={classes["cancel-btn"]}
                      onClick={() => {
                        setIsEditingModule(false);
                        setCurrentId(null);
                      }}
                    >
                      cancel
                    </button>
                    <button
                      className={classes["submit-btn"]}
                      onClick={() => submitModuleHandler(data.module_id, index)}
                    >
                      save changes
                    </button>
                  </div>
                ) : (
                  <div className={classes["module-btn-container"]}>
                    <DeleteBtn
                      className={classes["delete-module-btn"]}
                      ondelete={moduleDeleteHandler}
                      masterRoutes={"projectmodule"}
                      id={data.module_id}
                      deletingItem={"Module"}
                    />
                    <button
                      type="button"
                      onClick={() => moduleEditHandler(data.module_id, index)}
                      className={classes["edit-module-btn"]}
                    >
                      <img src={editModuleIcon} alt="edit-icon" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <Toast/>
    </Box>
  );
};

export default ViewProject;
