import React, { useEffect, useState } from "react";
import classes from "./MasterProjectTypeForm.module.css";

// component
import Box from "../../../shared/structures/Box";
import Form from "../../../shared/form/FormStructure";
import SmallBox from "../../../shared/structures/SmallBox";
import Input from "../../../shared/inputs/Input";
import DeleteBtn from "../../../shared/buttons/deleteButton/DeleteBtn";
import EditBtn from "../../../shared/buttons/editButton/EditBtn";
import EditModal from "../../../shared/modal/EditModal";
import axios from "axios";
import {  toast } from 'react-toastify';
import Toast from "../../toast/toast";

const ProjectTypeForm = () => {
  const [masterProjectTypeVal, setMasterProjectTypeVal] = useState("");
  const [editedProjectType, setEditedProjectType] = useState("");
  const [projectId, setProjectId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [reFetch, setReFetch] = useState(false);

  const masterEditProjectTypeChangeHandler = (e) => {
    setEditedProjectType(e.target.value);
  };
  const masterProjectTypeChangeHandler = (e) => {
    setMasterProjectTypeVal(e.target.value);
  };

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/masterprojecttype`
        );
        setTableData(res.data);
      } catch (error) {
        throw new Error("Some thing Wrong", error);
      }
    };
    fetchTableData();
  }, [reFetch]);

  const submitMasterProjectTypeFormHandler = async (e) => {
    e.preventDefault();
    await axios.post(`${process.env.REACT_APP_BASE_URL}/masterprojecttype`, {
      project_type: masterProjectTypeVal,
    });
    setReFetch(!reFetch);
    setMasterProjectTypeVal("");
    toast.success("Master project submited successfully.")
  };
  const updateHandler = async (e) => {
    e.preventDefault();
    await axios.put(
      `${process.env.REACT_APP_BASE_URL}/masterprojecttype/update/${projectId}`,
      {
        project_type: editedProjectType,
      }
    );
    setModalShow(false);
    setReFetch(!reFetch);
    setEditedProjectType("");
    toast.success("Master project update successfully.")
  };
  const deleteHandler = () => {
    toast.error("Master project deleted successfully")
    setReFetch(!reFetch);
  };
  const editHandler = (data) => {
    setEditedProjectType(data.project_type);
    setProjectId(data.id);
    setModalShow(true);
  };

  const resetHandler = () => {
    setMasterProjectTypeVal("");
  };

  let i = 1;
  const astric = <sup>*</sup>;

  return (
    <>
      <Box
        iconName="pencil-outline"
        boxTitle="Add Project Type"
        info="Master Project Type Info"
        parentBreadCrumb="Dashboard"
      >
        <Form
          onSubmit={submitMasterProjectTypeFormHandler}
          onreset={resetHandler}
          btn1Title="Reset"
          btn2Title="Save"
        >
          <SmallBox>
            <div className={classes["inp-box"]}>
              <Input
                label="Project Type"
                type="text"
                name="project_type"
                placeholder="Enter Project Type"
                onChange={masterProjectTypeChangeHandler}
                value={masterProjectTypeVal}
                required="required"
                astric={astric}
              ></Input>
            </div>
          </SmallBox>
        </Form>
      </Box>
      <table className={classes["table"]}>
        <thead className={classes.thead}>
          <tr>
            <th>Sr.no</th>
            <th>Project Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className={classes.tbody}>
          {tableData.length === 0 ? (
            <tr>
              <td className={classes["no-data"]} colSpan={4}>
                NO DATA
              </td>
            </tr>
          ) : (
            tableData.map((data) => (
              <tr key={data.id}>
                <td>{i++}</td>
                <td>{data.project_type}</td>
                <td>
                  <EditBtn
                    onClick={() => {
                      editHandler(data);
                    }}
                  />
                  {modalShow && (
                    <EditModal
                      show={modalShow}
                      title={"Master Project Type Edit"}
                      onHide={() => {
                        setModalShow(false);
                      }}
                    >
                      <Form
                        onSubmit={updateHandler}
                        onreset={resetHandler}
                        btn1Title="Reset"
                        btn2Title="Save"
                      >
                        <SmallBox>
                          <Input
                            label="Project Type"
                            type="text"
                            name="editProjectType"
                            onChange={masterEditProjectTypeChangeHandler}
                            value={editedProjectType}
                            placeholder="Enter project_type"
                            required="required"
                            astric={astric}
                          />
                        </SmallBox>
                      </Form>
                    </EditModal>
                  )}
                  <DeleteBtn
                    ondelete={deleteHandler}
                    id={data.id}
                    masterRoutes={"masterprojecttype"}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  );
};

export default ProjectTypeForm;
