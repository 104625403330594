import React, { useState } from "react";
import classes from "./Main.module.css";

import Sidebar from "../components/sidebar/Sidebar";

const Main = ({ onSideBar, isSideBarClose }) => { 
  return (
    <main className={classes.main}>
      <Sidebar onSideBar={onSideBar} isSideBarClose={isSideBarClose} />
    </main>
  );
};

export default Main;
