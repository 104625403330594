import React, { useEffect, useState } from "react";
import classes from "./ViewDomain.module.css";
import { formatDate } from "../../../../helpers";
import { formatFullDate } from "../../../../helpers";
import axios from "axios";
import SerialIcon from "../../../../assets/customer-view-asstes/serial.png";
import DomainIcon from "../../../../assets/customer-view-asstes/country.png";
import ExtensionIcon from "../../../../assets/customer-view-asstes/extension.png";
import CalendarIcon from "../../../../assets/customer-view-asstes/calendar.png";
import ActionIcon from "../../../../assets/customer-view-asstes/action-icon.png";

// Component

import EditModal from "../../../../shared/modal/EditModal";
import FormStructure from "../../../../shared/form/FormStructure";
import Input from "../../../../shared/inputs/Input";
import SmallBox from "../../../../shared/structures/SmallBox";
import DeleteBtn from "../../../../shared/buttons/deleteButton/DeleteBtn";
import EditBtn from "../../../../shared/buttons/editButton/EditBtn";

const ViewDomain = ({ code }) => {
  const [domainData, setDomainData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [extensionData, setExtensionData] = useState([]);
  const [reFetch, setReFetch] = useState(false);
  const [allDomainVal, setAllDomainVal] = useState({
    id: "",
    domain_name: "",
    extension: "--select--",
    no_of_years: "--select--",
    date_from: "",
    date_to: "dd-mm-yyyy",
  });
  const domainChangeHandler = (e) => {
    setAllDomainVal({
      ...allDomainVal,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const fetchDomainData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/service/domain/${code}`
      );
      setDomainData(res.data);
    };
    if (!!code) {
      fetchDomainData();
    }
  }, [reFetch, code]);

  useEffect(() => {
    const fetchExtensions = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/masterdomain`
      );
      setExtensionData(res.data);
    };
    fetchExtensions();
  }, []);

  const addDomainServiceHandler = (data) => {
    setAllDomainVal({
      id: data.id,
      domain_name: data.domain_name,
      extension: data.extension,
      no_of_years: data.no_of_years,
      date_from: data.date_from.split("-").reverse().join("-"),
      date_to: data.date_to,
    });
    setModalShow(true);
  };

  const domainSubmitHandler = async (e) => {
    e.preventDefault();
    if (
      allDomainVal.domain_name.trim() == "" ||
      allDomainVal.no_of_years == "--select--" ||
      allDomainVal.extension == "--select--" ||
      allDomainVal.date_from.trim() == "" ||
      allDomainVal.date_to.trim() == "dd-mm-yyyy"
    ) {
      alert("please enter all the field");
      return;
    }
    await axios.put(
      `${process.env.REACT_APP_BASE_URL}/service/domain/update/${allDomainVal.id}`,
      {
        domain_name: allDomainVal.domain_name.toLowerCase(),
        extension: allDomainVal.extension.toLowerCase(),
        no_of_years: allDomainVal.no_of_years,
        date_from: allDomainVal.date_from,
        date_to: allDomainVal.date_to,
        code: code,
        service_type: "domain",
      }
    );
    setAllDomainVal({
      domain_name: "",
      no_of_years: "--select--",
      extension: "--select--",
      date_from: "",
      date_to: "dd-mm-yyyy",
    });
    setModalShow(false);
    setReFetch(!reFetch);
  };
  const resetHandler = () => {
    setAllDomainVal({
      domain_name: "",
      no_of_years: "--select--",
      extension: "--select--",
      date_from: "",
      date_to: "dd-mm-yyyy",
    });
  };

  const deleteHandler = () => {
    setReFetch(!reFetch);
  };

  useEffect(() => {
    if (allDomainVal.date_from !== "" && allDomainVal.no_of_years !== "") {
      let date = new Date(allDomainVal.date_from);
      date.setFullYear(date.getFullYear() + parseInt(allDomainVal.no_of_years));
      setAllDomainVal({
        ...allDomainVal,
        date_to: formatDate(date),
      });
    }
  }, [allDomainVal.date_from, allDomainVal.no_of_years]);

  const astric = <sup>*</sup>;

  return (
    <>
      {domainData.length === 0 ? (
        <div className={classes.empty}>Domain Service not purchased yet!!!</div>
      ) : (
        <div className={classes["table"]}>
          <div className={classes["header"]}>
            <div>
              <img src={SerialIcon} alt="serialIcon" />
              <span>Sr.No</span>
            </div>
            <div>
              <img src={DomainIcon} alt="domain-Icon" />
              <span>Domain Name</span>
            </div>
            <div>
              <img src={ExtensionIcon} alt="extension" />
              <span>Extension</span>
            </div>
            <div>
              <img src={CalendarIcon} alt="date-of-purchase" />
              <span>D.O.Purchase</span>
            </div>
            <div>
              <img src={CalendarIcon} alt="calender-icon" />
              <span>D.O.Expiry</span>
            </div>
            <div>
              <img src={ActionIcon} alt="action-icon" />
              <span>Action</span>
            </div>
          </div>
          <div className={classes["body"]}>
            {domainData.map((data, i) => (
              <div key={i} className={classes["tr"]}>
                <div>
                  <span>{i + 1}</span>
                </div>
                <div>
                  <span>{data.domain_name}</span>
                </div>
                <div>
                  <span>{data.extension}</span>
                </div>
                <div>
                  <span>{data.date_from}</span>
                </div>
                <div>
                  <span>{data.date_to}</span>
                </div>
                <div>
                  <span className={classes["action-container"]}>
                    <EditBtn onClick={() => addDomainServiceHandler(data)} />
                    <DeleteBtn
                      ondelete={deleteHandler}
                      id={data.id}
                      masterRoutes={"service/domain"}
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>
          {modalShow && (
            <EditModal
              show={modalShow}
              title={"Domain service edit"}
              onHide={() => {
                setModalShow(false);
              }}
            >
              <FormStructure
                onSubmit={domainSubmitHandler}
                onreset={resetHandler}
                btn1Title="Reset"
                btn2Title="Save"
              >
                <SmallBox>
                  <Input
                    label="Domain"
                    type="text"
                    name="domain_name"
                    placeholder="Enter Domain"
                    onChange={domainChangeHandler}
                    required="required"
                    value={allDomainVal.domain_name}
                    astric={astric}
                  />
                  <div className={classes["inp-box"]}>
                    <label className={classes.label}>Extension</label>
                    <sup>*</sup>
                    <select
                      onChange={domainChangeHandler}
                      value={allDomainVal.extension}
                      className={classes["drop-down"]}
                      name="extension"
                      required
                    >
                      <option value="--select--">--select--</option>
                      {extensionData.map((data) => {
                        return (
                          <option key={data.id} value={data.extension}>
                            {data.extension}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </SmallBox>
                <SmallBox>
                  <label className={classes.label}>No of Years</label>
                  <sup>*</sup>
                  <select
                    onChange={domainChangeHandler}
                    value={allDomainVal.no_of_years}
                    className={classes.select}
                    name="no_of_years"
                    required
                  >
                    <option value="--select--">--select--</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>

                  <label className={classes.label}>Date From</label>
                  <sup>*</sup>
                  <input
                    className={classes.select}
                    type="date"
                    name="date_from"
                    onChange={domainChangeHandler}
                    required="required"
                    value={allDomainVal.date_from}
                    astric={astric}
                  />

                  <label className={classes.label}>Date to</label>
                  <sup>*</sup>
                  <input
                    className={classes.select}
                    type="text"
                    name="date_to"
                    onChange={domainChangeHandler}
                    readOnly
                    value={allDomainVal.date_to}
                  />
                </SmallBox>
              </FormStructure>
            </EditModal>
          )}
        </div>
      )}
    </>
  );
};

export default ViewDomain;
