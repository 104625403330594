import React, { useState, useEffect, useMemo } from "react";
import {
  usePagination,
  useSortBy,
  useTable,
  useGlobalFilter,
} from "react-table";
import { useNavigate } from "react-router-dom";
import ProjectGlobalFilter from "./ProjectGlobalFilter";
import axios from "axios";
import DownArrow from "../../../assets/down.png";
import UpArrow from "../../../assets/up.png";
import Box from "../../../shared/structures/Box";
import classes from "./ProjectList.module.css";

const ProjectList = () => {
  const [projectData, setProjectData] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [customerName, setCustomerName] = useState("all");

  const navigate = useNavigate();
  useEffect(() => {
    const fetchProjectData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/projectlist`
      );
      setProjectData(res.data);
    };
    fetchProjectData();
  }, []);

  useEffect(() => {
    if (projectData?.length) {
      const newVar = projectData?.map((project) => project?.customer_name);
      setProjectList([...new Set(newVar)]);
    }
  }, [projectData]);

  console.log(projectList);

  const customerChangeHandler = (e) => {
    setCustomerName(e.target.value);
  };
  const column = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Project Name",
        accessor: "project_name",
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
      },
      {
        Header: "Project Type",
        accessor: "project_type",
      },
    ],
    []
  );

  const filteredProjectData = projectData.filter((data) => {
    if (customerName === "all") {
      return projectData;
    } else if (data.customer_name === customerName) {
      return data.customer_name;
    }
  });

  const Table = ({ columns, data }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      nextPage,
      previousPage,
      canNextPage,
      canPreviousPage,
      pageOptions,
      gotoPage,
      pageCount,
      prepareRow,
      state,
      setGlobalFilter,
    } = useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    const { globalFilter, pageIndex } = state;

    const dropdown = (
      <>
        <ProjectGlobalFilter
          filter={globalFilter}
          setFilter={setGlobalFilter}
        />
        <select
          value={customerName}
          onChange={customerChangeHandler}
          className={classes["drop-down"]}
        >
          <option value="all">All</option>
          {projectList.map((data) => {
            return (
              <option key={data} value={data}>
                {data}
              </option>
            );
          })}
        </select>
      </>
    );

    return (
      <Box
        iconName="person-outline"
        boxTitle="Project list"
        info="Project list"
        parentBreadCrumb="dashboard"
        dropdown={dropdown}
      >
        <div className={classes.container}>
          <table className={classes.table} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className={classes.header}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img
                              className={classes["down-arrow"]}
                              src={DownArrow}
                            />
                          ) : (
                            <img
                              className={classes["up-arrow"]}
                              src={UpArrow}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <tr className={classes["expired-data"]}>
                  <td className={classes.td}>NO DATA!!</td>
                </tr>
              ) : (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      onClick={() => {
                        navigate("/projectlist/viewproject", {
                          state: { project_id: row.original.project_id },
                        });
                      }}
                      className={classes["body-row"]}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td className={classes.td} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <div className={classes["pagination-container"]}>
            <button
              className={
                classes[!canPreviousPage ? "inv-goto-btn" : "goto-btn"]
              }
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <i className="bx bx-fast-forward bx-rotate-180"></i>
            </button>
            <button
              className={
                classes[!canPreviousPage ? "inv-page-btn" : "page-btn"]
              }
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous Page
            </button>
            <span className={classes["page-count"]}>
              {" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <button
              className={classes[!canNextPage ? "inv-page-btn" : "page-btn"]}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next Page
            </button>
            <button
              className={classes[!canNextPage ? "inv-goto-btn" : "goto-btn"]}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <i className="bx bx-fast-forward"></i>
            </button>
          </div>
        </div>
      </Box>
    );
  };
  return <Table columns={column} data={filteredProjectData} />;
};

export default ProjectList;
