import React, { useState } from "react";
import classes from "./Dashboard.module.css";
import ExpireTable from "../../components/expireTable/ExpireTable";

const Dashboard = () => {
const [isServiceSelected,setServiceSelected]=useState(true)

  return (
    <div className={classes["dashboard"]}>
      <div className={classes["header"]}>
        <h2 className={classes.heading}>Dashboard</h2>
        <span>
          <button onClick={()=>{setServiceSelected(true)}} className={classes[isServiceSelected?'service-btn-selected' :'service-btn']}>Services</button>
          <button onClick={()=>{setServiceSelected(false)}} className={classes[isServiceSelected? 'project-btn':'project-btn-selected']}>Project</button>
        </span>
      </div>
      <ExpireTable isServiceSelected={isServiceSelected} />
    </div>
  );
};

export default Dashboard;
