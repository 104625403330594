import React, { useEffect, useState } from "react";
import classes from "./Services.module.css";
import axios from "axios";
import Select from "react-select/creatable";
// Component
import Box from "../../shared/structures/Box";
import SmallBox from "../../shared/structures/SmallBox";
import DomainForm from "./domain/DomainForm";
import EmailForm from "./email/EmailForm";
import SslForm from "./ssl/SslForm";
import HostingForm from "./hosting/HostingForm";

const Services = () => {
  const [customerName, setCustomerName] = useState([]);
  const [selectedService, setSelectedService] = useState("Select...");
  const [selectedCustomerName, setSelectedCustomerName] = useState("");


  useEffect(() => {
    const fetchCompanyName = async () => {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/customer`);
      setCustomerName(res.data);
    };
    fetchCompanyName();
  }, []);


  const customerNames = customerName.map((data) => {
    return {
      value: data.code,
      label: data.customer_name,
    };
  });

  const serviceChangeHandler = (e) => {
    setSelectedService(e.target.value);
  };
  const handleCustomerChange = (event) => {
    setSelectedCustomerName(event.value);
  };
  let title = () => {
    if (selectedService === "Domain") {
      return "Domain Service";
    } else if (selectedService === "Hosting") {
      return "Hosting Service";
    } else if (selectedService === "Email") {
      return "Email Service";
    } else if (selectedService === "SSL") {
      return "SSL Service";
    } else {
      return "Service";
    }
  };
  return (
    <Box
      iconName="pencil-outline"
      boxTitle="Add Services"
      info={title()}
      parentBreadCrumb="dashboard"
    >
      <SmallBox>
        <div className={classes["inp-box"]}>
          <label className={classes["label"]}>Customer Names</label>
          <sup>*</sup>
          <Select
            className={classes["drop-down-select"]}
            onChange={handleCustomerChange}
            options={customerNames}
          />
         
        </div>
        <div className={classes["inp-box"]}>
          <label className={classes.label}>Service Type</label>
          <sup>*</sup>
          <select
            onChange={serviceChangeHandler}
            className={classes["drop-down"]}
            value={selectedService}
          >
            <option value="Select...">Select...</option>
            <option value="Domain">Domain</option>
            <option value="Hosting">Hosting</option>
            <option value="Email">Email</option>
            <option value="SSL">SSL</option>
          </select>
        </div>
      </SmallBox>
      {selectedService === "Select..." && ""}
      {selectedService === "Domain" && selectedCustomerName !== "" ? (
        <DomainForm serviceType={selectedService} code={selectedCustomerName} />
      ) : (
        ""
      )}
      {selectedService === "Hosting" && selectedCustomerName !== "" ? (
        <HostingForm
          serviceType={selectedService}
          code={selectedCustomerName}
        />
      ) : (
        ""
      )}
      {selectedService === "Email" && selectedCustomerName !== "" ? (
        <EmailForm serviceType={selectedService} code={selectedCustomerName} />
      ) : (
        ""
      )}
      {selectedService === "SSL" && selectedCustomerName !== "" ? (
        <SslForm serviceType={selectedService} code={selectedCustomerName} />
      ) : (
        ""
      )}
    </Box>
  );
};

export default Services;
