import React, { useState } from "react";
import classes from "./ChangePassword.module.css";

// Component
import Box from "../../shared/structures/Box";
import FormStructure from "../../shared/form/FormStructure";
import SmallBox from "../../shared/structures/SmallBox";
import Input from "../../shared/inputs/Input";
import axios from "axios";

const ChangePassword = () => {
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(true);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const ChangeOldPasswordHandler = (e) => {
    setOldPassword(e.target.value);
    setIsPasswordCorrect(true)
  };
  const ChangeNewPasswordHandler = (e) => {
    setNewPassword(e.target.value);
  };
  const user_id = JSON.parse(localStorage.getItem("user"));

  const submitNewPassword = async (e) => {
    e.preventDefault();
    if (oldPassword.trim() !== "" || newPassword.trim() !== "") {
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/updatepassword/${user_id.user_id}`,
        {
          old_password: oldPassword,
          new_password: newPassword,
        }
      );
      if (res.data === "Incorrect Password") {
        setIsPasswordCorrect(false)
      }else{
        setIsPasswordCorrect(true)
        setOldPassword("")
        setNewPassword("")
      }
    }
  };
  const resetHandler = () => {
    setOldPassword("");
    setNewPassword("");
  };
  const astric = <sup>*</sup>;
  return (
    <Box
      iconName="pencil-outline"
      boxTitle="Change Password"
      info="Change Password"
      parentBreadCrumb="dashboard"
    >
      <FormStructure
        onSubmit={submitNewPassword}
        onreset={resetHandler}
        btn1Title="Reset"
        btn2Title="Save"
      >
        <SmallBox>
          <div className={classes["inp-box"]}>
            <Input
              label="Old Password"
              type="password"
              placeholder="Enter Old Password"
              onChange={ChangeOldPasswordHandler}
              value={oldPassword}
              required="required"
              className={isPasswordCorrect ? "" : "invalid"}
              astric={astric}
            />
          </div>
        </SmallBox>
        <SmallBox>
          <div className={classes["inp-box"]}>
            <Input
              label="New Password"
              type="password"
              onChange={ChangeNewPasswordHandler}
              value={newPassword}
              placeholder="Enter New Password"
              required="required"
              astric={astric}
            />
          </div>
        </SmallBox>
      </FormStructure>
    </Box>
  );
};

export default ChangePassword;
