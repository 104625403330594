import React, { useEffect, useState } from "react";

import classes from "./CustomerForm.module.css";
import Button from "react-bootstrap/Button";
import EditModal from "../../../shared/modal/EditModal";
import axios from "axios";
import Form from "react-bootstrap/Form";

// Component
import Box from "../../../shared/structures/Box";
import FormStructure from "../../../shared/form/FormStructure";
import SmallBox from "../../../shared/structures/SmallBox";
import Input from "../../../shared/inputs/Input";
import { countryArr } from "../../../assets/Country";
import { stateArr } from "../../../assets/State";
import DropDown from "../../../shared/dropdown/DropDown";
import { toast } from "react-toastify";
import Toast from "../../toast/toast";

const CustomerForm = () => {
  const [isRender, setIsRender] = useState(false);
  const [setCode, isSetCode] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");
  const [countrySearchVal, setCountrySearchVal] = useState("");
  const [stateSearchVal, setStateSearchVal] = useState("");
  const [isGstExempted, setIsGstExempted] = useState(false);
  const [allCustomerVal, setAllCustomerVal] = useState({
    customerType: "Select...",
    name: "",
    email: "",
    mobile: "",
    tel_No: "",
    alternate_No: "",
    address1: "",
    address2: "",
    city: "",
    zip_code: "",
    PAN_No: "",
    GST_No: "",
    contact_person: "",
    designation: "",
    contact_person_mobile: "",
    contact_person_email: "",
  });

  useEffect(() => {
    const fetchCode = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/customercode`
      );
      isSetCode(res.data.code);
    };
    fetchCode();
  }, [isRender]);

  const customerChangeHandler = (e) => {
    setAllCustomerVal({
      ...allCustomerVal,
      [e.target.name]: e.target.value,
    });
  };

  const data = stateArr.filter((data) => data.country_name === country);

  let gstRegex = new RegExp(
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
  );
  let regPan = new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/);

  const submitCustomerFormHandler = async (e) => {
    e.preventDefault();
    if (
      allCustomerVal.GST_No &&
      allCustomerVal.GST_No !== "exempted" &&
      !gstRegex.test(allCustomerVal.GST_No)
    ) {
      alert("Enter Valid Gst number");
      return;
    }
    if (allCustomerVal.PAN_No && !regPan.test(allCustomerVal.PAN_No)) {
      alert("Enter Valid PAN number");
      return;
    }
    if (
      allCustomerVal.name.trim() == "" ||
      allCustomerVal.zip_code.trim() == "" ||
      allCustomerVal.city.trim() == "" ||
      state.trim() == "" ||
      country.trim() == "" ||
      allCustomerVal.mobile.trim() == "" ||
      allCustomerVal.email.trim() == "" ||
      allCustomerVal.address1.trim() == "" ||
      allCustomerVal.address2.trim() == ""
    ) {
      alert("Please Enter All Required Field");
      return;
    }
    await fetch(`${process.env.REACT_APP_BASE_URL}/customer`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        code: setCode,
        customerType: allCustomerVal.customerType,
        name: allCustomerVal.name,
        email: allCustomerVal.email,
        mobile: allCustomerVal.mobile,
        tel_No: allCustomerVal.tel_No,
        alternate_No: allCustomerVal.alternate_No,
        address1: allCustomerVal.address1,
        address2: allCustomerVal.address2,
        city: allCustomerVal.city,
        zip_code: allCustomerVal.zip_code,
        state: state,
        country: country,
        PAN_No: allCustomerVal.PAN_No,
        GST_No: isGstExempted ? "exempted" : allCustomerVal.GST_No,
      }),
    });
    setAllCustomerVal({
      code: setCode,
      customerType: "Select...",
      name: "",
      email: "",
      mobile: "",
      tel_No: "",
      alternate_No: "",
      address1: "",
      address2: "",
      city: "",
      zip_code: "",
      PAN_No: "",
      GST_No: "",
      contact_person: "",
      designation: "",
      contact_person_mobile: "",
      contact_person_email: "",
    });
    toast.success("Customer added successfully");
    setIsRender(!isRender);
    setIsGstExempted(false);
    setCountry("");
    setState("");
    setSelectedCountryName("");
    setSelectedStateName("");
    setCountrySearchVal("");
    setStateSearchVal("");
  };

  const submitContactPerson = async (e) => {
    e.preventDefault();
    await fetch(`${process.env.REACT_APP_BASE_URL}/contactperson`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        code: setCode,
        contact_person: allCustomerVal.contact_person,
        designation: allCustomerVal.designation,
        contact_person_mobile: allCustomerVal.contact_person_mobile,
        contact_person_email: allCustomerVal.contact_person_email,
      }),
    });
    setModalShow(false);
  };

  const getSelectedCountryName = (value) => {
    setCountry(value);
  };

  const getSelectedStateName = (value) => {
    setState(value);
  };

  const resetHandler = () => {
    setAllCustomerVal({
      code: setCode,
      customerType: "Select...",
      name: "",
      email: "",
      mobile: "",
      tel_No: "",
      alternate_No: "",
      address1: "",
      address2: "",
      city: "",
      zip_code: "",
      state: "",
      country: "",
      PAN_No: "",
      GST_No: "",
      contact_person: "",
      designation: "",
      contact_person_mobile: "",
      contact_person_email: "",
    });
    setCountry("");
    setState("");
    setSelectedCountryName("");
    setSelectedStateName("");
    setCountrySearchVal("");
    setStateSearchVal("");
  };
  const exemptHandler = () => {
    setIsGstExempted((prev) => {
      return !prev;
    });
  };

  const addContactPerson = (
    <Button
      onClick={() => {
        if (
          allCustomerVal.name.trim() === "" ||
          allCustomerVal.zip_code.trim() === "" ||
          allCustomerVal.city.trim() === "" ||
          state.trim() === "" ||
          country.trim() === "" ||
          allCustomerVal.mobile.trim() === "" ||
          allCustomerVal.email.trim() === "" ||
          allCustomerVal.address1.trim() === "" ||
          allCustomerVal.address2.trim() === ""
        ) {
          toast.warning("Please add required field");
          return;
        }
        setModalShow(true);
      }}
      className={classes["add-contact-btn"]}
      size="sm"
    >
      <ion-icon name="person-add-outline"></ion-icon>
    </Button>
  );
  const astric = <sup>*</sup>;

  return (
    <>
      <Box
        iconName="pencil-outline"
        boxTitle="Add Customer"
        info="Customer Info"
        parentBreadCrumb="dashboard"
      >
        <FormStructure
          onSubmit={submitCustomerFormHandler}
          onreset={resetHandler}
          btn1Title="Reset"
          btn2Title="Save"
          extraBtn={
            allCustomerVal.customerType === "individual" ||
            allCustomerVal.customerType === "select"
              ? ""
              : addContactPerson
          }
        >
          <div className={classes["customer-type"]}>
            <label>Customer type</label>
            <sup>*</sup>
            <select
              onChange={customerChangeHandler}
              className={classes["select-option"]}
              name="customerType"
              value={allCustomerVal.customerType}
            >
              <option value="select">--select--</option>
              <option value="company">Company</option>
              <option value="individual">Individual</option>
            </select>
          </div>
          {allCustomerVal.customerType === "select" ? (
            ""
          ) : (
            <>
              <SmallBox>
                <Input
                  label="Code"
                  type="text"
                  name="code"
                  placeholder="Enter Code"
                  onChange={customerChangeHandler}
                  value={setCode}
                  required="required"
                  astric={astric}
                  readOnly="readonly"
                />
                <Input
                  label={
                    allCustomerVal.customerType === "individual"
                      ? "Name"
                      : "Company name"
                  }
                  type="text"
                  name="name"
                  placeholder={
                    allCustomerVal.customerType === "individual"
                      ? "Enter Name"
                      : "Enter Company Name"
                  }
                  onChange={customerChangeHandler}
                  value={allCustomerVal.name}
                  required="required"
                  astric={astric}
                />
              </SmallBox>
              <SmallBox>
                <Input
                  label="Email"
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  onChange={customerChangeHandler}
                  value={allCustomerVal.email}
                  required="required"
                  astric={astric}
                />
                <Input
                  label="Mobile no."
                  type="number"
                  name="mobile"
                  placeholder="Enter Mobile number"
                  onChange={customerChangeHandler}
                  value={allCustomerVal.mobile}
                  required="required"
                  astric={astric}
                />
              </SmallBox>

              <SmallBox>
                <Input
                  label="Alternate no."
                  type="number"
                  name="alternate_No"
                  placeholder="Enter Alternate Number"
                  onChange={customerChangeHandler}
                  value={allCustomerVal.alternate_No}
                />
                <Input
                  label="Landline no."
                  type="number"
                  name="tel_No"
                  placeholder="Enter Landline number"
                  onChange={customerChangeHandler}
                  value={allCustomerVal.tel_No}
                />
              </SmallBox>
              <SmallBox>
                <Input
                  label="Zip Code"
                  type="number"
                  name="zip_code"
                  placeholder="Enter Zip Code"
                  onChange={customerChangeHandler}
                  value={allCustomerVal.zip_code}
                  required="required"
                  astric={astric}
                />
                <DropDown
                  label="Country"
                  optionOutput={"value"}
                  optionVal={"value"}
                  optionArr={countryArr}
                  getVal={getSelectedCountryName}
                  onSearch={countrySearchVal}
                  setSearch={setCountrySearchVal}
                  onSelect={selectedCountryName}
                  setSelected={setSelectedCountryName}
                />
              </SmallBox>
              <SmallBox>
                <DropDown
                  label="State"
                  optionOutput={"value"}
                  optionVal={"value"}
                  optionArr={data}
                  getVal={getSelectedStateName}
                  onSearch={stateSearchVal}
                  setSearch={setStateSearchVal}
                  onSelect={selectedStateName}
                  setSelected={setSelectedStateName}
                />
                <Input
                  label="City"
                  type="text"
                  name="city"
                  placeholder="Enter City"
                  onChange={customerChangeHandler}
                  value={allCustomerVal.city}
                  required="required"
                  astric={astric}
                />
              </SmallBox>
              <SmallBox>
                <div className={classes["text-box"]}>
                  <label className={classes["text-area-label"]}>
                    Address 1
                  </label>
                  <sup>*</sup>
                  <textarea
                    name="address1"
                    className={classes["text-area"]}
                    placeholder="Enter Address"
                    value={allCustomerVal.address1}
                    onChange={customerChangeHandler}
                    rows="2"
                    required
                  />
                </div>
              </SmallBox>
              <SmallBox>
                <div className={classes["text-box"]}>
                  <label className={classes["text-area-label"]}>
                    Address 2
                  </label>
                  <sup>*</sup>
                  <textarea
                    name="address2"
                    className={classes["text-area"]}
                    placeholder="Enter Address"
                    value={allCustomerVal.address2}
                    onChange={customerChangeHandler}
                    rows="2"
                    required
                  />
                </div>
              </SmallBox>

              <SmallBox>
                <div className={classes["inp-box"]}>
                  <label className={classes["inp-label"]}>PAN No.</label>
                  <input
                    className={classes["inp"]}
                    type="text"
                    name="PAN_No"
                    placeholder="Enter Pan Number"
                    onChange={customerChangeHandler}
                    value={allCustomerVal.PAN_No}
                  />
                </div>
                <div className={classes["inp-box"]}>
                  <label
                    className={
                      classes[isGstExempted ? "label-disable" : "inp-label"]
                    }
                  >
                    GST no.
                  </label>
                  <input
                    className={classes["inp"]}
                    type="text"
                    name="GST_No"
                    readOnly={isGstExempted}
                    placeholder="Enter Gst Number"
                    onChange={customerChangeHandler}
                    value={allCustomerVal.GST_No}
                  />
                </div>
              </SmallBox>
              <div className={classes["check-container"]}>
                <label className={classes["check-bx-label"]} htmlFor="Exempted">
                  GST Exempted :
                </label>
                <Form.Check
                  onChange={exemptHandler}
                  type="switch"
                  className={classes["check-inp"]}
                />
              </div>
            </>
          )}
        </FormStructure>
        <Toast />
      </Box>
      {modalShow && (
        <EditModal
          show={modalShow}
          title={"Add Contact Person"}
          onHide={() => {
            setModalShow(false);
          }}
        >
          <FormStructure
            onSubmit={submitContactPerson}
            onreset={resetHandler}
            btn1Title="Reset"
            btn2Title="+ Contact Person"
          >
            <SmallBox>
              <Input
                label="Name"
                type="text"
                name="contact_person"
                placeholder="Enter Name"
                required="required"
                onChange={customerChangeHandler}
                value={allCustomerVal.contact_person}
                astric={astric}
              />
              <Input
                label="Designation"
                type="text"
                name="designation"
                placeholder="Enter Designation"
                required="required"
                onChange={customerChangeHandler}
                value={allCustomerVal.designation}
                astric={astric}
              />
            </SmallBox>
            <SmallBox>
              <Input
                label="Mobile"
                type="number"
                name="contact_person_mobile"
                onChange={customerChangeHandler}
                value={allCustomerVal.contact_person_mobile}
                placeholder="Enter Mobile no."
                required="required"
                astric={astric}
              />
              <Input
                label="Email"
                type="email"
                name="contact_person_email"
                onChange={customerChangeHandler}
                value={allCustomerVal.contact_person_email}
                placeholder="Enter Email"
                required="required"
                astric={astric}
              />
            </SmallBox>
          </FormStructure>
        </EditModal>
      )}
    </>
  );
};

export default CustomerForm;
