import React from 'react'
import classes from './SmallBox.module.css'

const SmallBox = (props) => {
  return (
    <div className={classes['small-box']}>
      {props.children}
    </div>
  )
}

export default SmallBox
