import React, { useEffect, useState } from "react";
import AcairaLogo from "../assets/logo.png";
import axios from "axios";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [isPassHide, setIsPassHide] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState({
    isValid: true,
    msg: "",
  });
  const [isUsernameValid, setIsUsernameValid] = useState({
    isValid: true,
    msg: "",
  });

  const userNameChangeHandler = (e) => {
    setIsUsernameValid({ isValid: true, msg: "" });
    setUserName(e.target.value);
  };
  const passwordChangeHandler = (e) => {
    setIsPasswordValid({ isValid: true, msg: "" });
    setPassword(e.target.value);
  };
  useEffect(() => {
    localStorage.clear();
  }, []);
  const loginSubmitHandler = async (e) => {
    e.preventDefault();
    checkLength("user", username);
    checkLength("pass", password);
    try {
      let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, {
        username,
        password,
      });

      console.log(result);

      if (result?.data?.success) {
        setIsLogin(true);
        localStorage.setItem("token", JSON.stringify(result?.data?.token));
        localStorage.setItem(
          "user",
          JSON.stringify({
            user_id: result?.data?.user_id,
            username: result?.data?.username,
            role: result?.data?.role,
          })
        );

        setTimeout(() => {
          setIsLogin(false);
          navigate("/");
        }, 1000);
      }
    } catch (error) {
      if (error.response.data === "username is incorrect") {
        setIsUsernameValid({
          isValid: false,
          msg: "Username is incorrect.",
        });
      } else if (error.response.data === "Password is incorrect") {
        setIsUsernameValid({ isValid: true, msg: "" });
        setIsPasswordValid({ isValid: false, msg: "Password is incorrect" });
      }
    }
  };
  const checkLength = (name, state) => {
    if (name === "user") {
      if (state.length === 0) {
        setIsUsernameValid({
          isValid: false,
          msg: "Username is a required field.",
        });
      }
    } else if (name === "pass") {
      if (state.length === 0) {
        setIsPasswordValid({
          isValid: false,
          msg: "Password is a reuired field.",
        });
      }
    }
  };

  return (
    <div className=" h-full font-poppins  bg-white">
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        {isLogin ? (
          <div className="rounded-md bg-green-50 p-4">
            <div className="flex items-center justify-center">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="h-10 w-10 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-2xl m-0 font-medium text-green-800">
                  Loged in successfully
                </h3>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <img
                className="mx-auto h-15 w-auto"
                src={AcairaLogo}
                alt="company-logo"
              />
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form
                onSubmit={loginSubmitHandler}
                className="space-y-6"
                action="#"
                method="POST"
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-base font-medium leading-6 text-gray-900"
                  >
                    Username
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="username"
                      type="text"
                      value={username}
                      onBlur={() => checkLength("user", username)}
                      onFocus={() =>
                        setIsUsernameValid({ isValid: "true", msg: "" })
                      }
                      onChange={userNameChangeHandler}
                      autoComplete="username"
                      required
                      placeholder="Enter username"
                      className="block w-full rounded-md border-2 py-1.5  px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"
                    />
                  </div>
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {isUsernameValid.isValid ? null : isUsernameValid.msg}
                  </p>
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-base font-medium  leading-6 text-gray-900"
                    >
                      Password
                    </label>
                    <div className="text-base">
                      <a
                        href="#"
                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                      >
                        Forgot password?
                      </a>
                    </div>
                  </div>
                  <div className="relative mt-2">
                    <input
                      id="password"
                      name="password"
                      onChange={passwordChangeHandler}
                      value={password}
                      onBlur={() => checkLength("pass", password)}
                      onFocus={() =>
                        setIsPasswordValid({ isValid: "true", msg: "" })
                      }
                      type={isPassHide ? "password" : "text"}
                      autoComplete="current-password"
                      required
                      placeholder="Enter password"
                      className="block w-full rounded-md border-2 py-1.5   px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"
                    />
                    <div
                      onClick={() => setIsPassHide(!isPassHide)}
                      className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                      {isPassHide ? (
                        <EyeSlashIcon
                          className="h-5 w-5 text-grey-500"
                          aria-hidden="true"
                        />
                      ) : (
                        <EyeIcon
                          className="h-5 w-5 text-grey-500"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                  </div>

                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {isPasswordValid.isValid ? null : isPasswordValid.msg}
                  </p>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-blue-950 px-3 py-2  text-base font-semibold leading-6 text-white shadow-sm hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
    // <div className={classes.login}>
    //   <div className={classes.container}>
    //     <div className={classes["logo-box"]}>
    //       <div>
    //         <img src={AcairaLogo} alt="company-logo" />
    //       </div>
    //     </div>
    //     <Form onSubmit={loginSubmitHandler}>
    //       <InputGroup
    //         className={isUsernameValid ? "mt-3 mb-4" : "mt-3"}
    //         controlid="formBasicEmail"
    //       >
    //         <InputGroup.Text className={classes["basic-addon1"]}>
    //           <ion-icon name="person-circle-outline"></ion-icon>
    //         </InputGroup.Text>
    //         <input
    //           className={isUsernameValid ? classes.inp : classes["invalid-inp"]}
    //           value={username}
    //           onChange={userNameChangeHandler}
    //           type="text"
    //           placeholder="UserName"
    //         />
    //       </InputGroup>
    //       {isUsernameValid ? (
    //         ""
    //       ) : (
    //         <span className={classes["invalid-msg"]}>
    //           Username is incorrect!!!
    //         </span>
    //       )}
    //       <InputGroup
    //         className={isPasswordValid ? "mb-4" : ""}
    //         controlid="formBasicPassword"
    //       >
    //         <InputGroup.Text className={classes["basic-addon1"]}>
    //           <ion-icon name="lock-open"></ion-icon>
    //         </InputGroup.Text>
    //         <input
    //           className={
    //             isPasswordValid
    //               ? classes["inp-password"]
    //               : classes["pass-invalid"]
    //           }
    //           value={password}
    //           onChange={passwordChangeHandler}
    //           type={isPassHide ? "password" : "text"}
    //           placeholder="Password"
    //         />
    //         <InputGroup.Text
    //           onClick={() => setIsPassHide(!isPassHide)}
    //           className={
    //             classes[
    //               isPasswordValid ? "basic-addon2" : "basic-addon2-invalid"
    //             ]
    //           }
    //         >
    //           {isPassHide ? (
    //             <ion-icon name="eye-off"></ion-icon>
    //           ) : (
    //             <ion-icon name="eye"></ion-icon>
    //           )}
    //         </InputGroup.Text>
    //       </InputGroup>
    //       {isPasswordValid ? (
    //         ""
    //       ) : (
    //         <span className={classes["invalid-msg"]}>
    //           Password is incorrect!!!
    //         </span>
    //       )}
    //       <button className={classes.btn} type="submit">
    //         Login To Dashboard
    //       </button>
    //       <div className={classes["forget-pass"]}>Forget Password ?</div>
    //     </Form>
    //   </div>
    // </div>
  );
};

export default Login;
