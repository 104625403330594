import React from "react";
import classes from "./ExpireGlobalFilter.module.css"

const ExpireGlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className={classes["search-Bar"]}>
      <input value={filter || ""} placeholder='Search' onChange={(e) => setFilter(e.target.value)} />
    </div>
  );
};

export default ExpireGlobalFilter;