import React, { useState, useEffect } from "react";
import classes from "./SslForm.module.css";
import axios from "axios";
import Toast from "../../toast/toast";
import { toast } from "react-toastify";

// COMPONENTS
import SmallBox from "../../../shared/structures/SmallBox";
import Input from "../../../shared/inputs/Input";
import FormStructure from "../../../shared/form/FormStructure";
import { formatDate } from "../../../helpers";

const SslForm = ({ code, serviceType }) => {
  const [sslData, setSslData] = useState([]);

  const [allInpVal, setAllInpVal] = useState({
    domain_name: "",
    vendor: "--select--",
    no_of_years: "--select--",
    date_from: "",
    date_to: "dd-mm-yy",
  });
  const sslChangeHandler = (e) => {
    setAllInpVal({
      ...allInpVal,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const fetchSslData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/masterssl`
      );
      setSslData(res.data);
    };
    fetchSslData();
  }, []);

  const sslSubmitHandler = async (e) => {
    e.preventDefault();
    if (
      allInpVal.domain_name.trim() == "" ||
      allInpVal.no_of_years.trim() == "--select--" ||
      allInpVal.vendor.trim() == "--select--" ||
      allInpVal.date_from.trim() == "" ||
      allInpVal.date_to.trim() == "dd-mm-yy"
    ) {
      return;
    }
    await fetch(`${process.env.REACT_APP_BASE_URL}/service/ssl`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        domain_name: allInpVal.domain_name.toLowerCase(),
        vendor: allInpVal.vendor.toLowerCase(),
        no_of_years: allInpVal.no_of_years,
        date_from: allInpVal.date_from,
        date_to: allInpVal.date_to,
        code: code,
        service_type: serviceType.toLowerCase(),
      }),
    });
    toast.success("SSL service added successfully");
    setAllInpVal({
      domain_name: "",
      vendor: "--select--",
      no_of_years: "--select--",
      date_from: "",
      date_to: "dd-mm-yy",
    });
  };
  const resetHandler = () => {
    setAllInpVal({
      domain_name: "",
      vendor: "--select--",
      no_of_years: "--select--",
      date_from: "",
      date_to: "dd-mm-yy",
    });
  };
  useEffect(() => {
    if (allInpVal.date_from !== "" && allInpVal.no_of_years !== "--select--") {
      let date = new Date(allInpVal.date_from);
      date.setFullYear(date.getFullYear() + parseInt(allInpVal.no_of_years));
      setAllInpVal({
        ...allInpVal,
        date_to: formatDate(date),
      });
    }
  }, [allInpVal.date_from, allInpVal.no_of_years]);

  const astric = <sup>*</sup>;
  return (
    <FormStructure
      onSubmit={sslSubmitHandler}
      onreset={resetHandler}
      btn1Title="Reset"
      btn2Title="Save"
    >
      <SmallBox>
        <Input
          label="Domain"
          type="text"
          name="domain_name"
          placeholder="Enter Domain"
          onChange={sslChangeHandler}
          required="required"
          value={allInpVal.domain_name}
          astric={astric}
        />
        <div className={classes["inp-box"]}>
          <label className={classes.label}>Vendor</label>
          <sup>*</sup>
          <select
            name="vendor"
            value={allInpVal.vendor}
            onChange={sslChangeHandler}
            className={classes["drop-down"]}
            required
          >
            <option value="--select--">--select--</option>
            {sslData.map((data) => {
              return (
                <option key={data.id} value={data.vendor}>
                  {data.vendor}
                </option>
              );
            })}
          </select>
        </div>
      </SmallBox>
      <SmallBox>
        <div className={classes.container}>
          <div className={classes["select-box"]}>
            <label className={classes.label}>No of Years</label>
            <sup>*</sup>
            <select
              onChange={sslChangeHandler}
              name="no_of_years"
              value={allInpVal.no_of_years}
              className={classes.select}
              required
            >
              <option value="--select--">--select--</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
          <div className={classes["select-box"]}>
            <label className={classes.label}>Date From</label>
            <sup>*</sup>
            <input
              className={classes.select}
              type="date"
              name="date_from"
              onChange={sslChangeHandler}
              required="required"
              value={allInpVal.date_from}
              astric={astric}
            />
          </div>
          <div className={classes["select-box"]}>
            <label className={classes.label}>Date to</label>
            <sup>*</sup>
            <input
              className={classes.select}
              type="text"
              name="date_to"
              readOnly
              onChange={sslChangeHandler}
              required="required"
              value={allInpVal.date_to}
              astric={astric}
            />
          </div>
        </div>
      </SmallBox>
      <Toast />
    </FormStructure>
  );
};

export default SslForm;
