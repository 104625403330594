import React from 'react'
import classes from './FormStructure.module.css'
import Button from 'react-bootstrap/Button';

const Form = (props) => {
    const { onSubmit, btn1Title, btn2Title,onreset,extraBtn } = props;
    return (
        <form onSubmit={onSubmit} onReset={onreset}>
            {props.children}
            <div className={classes['btn-group']}>
                {extraBtn}
                <Button size='lg' variant="outline-danger" type='reset'>{btn1Title}</Button>{' '}
                <Button size='lg' variant="outline-success" type='submit'>{btn2Title}</Button>{' '}
            </div>

        </form>
    )
}

export default Form
