import React, { useEffect, useRef, useState } from "react";
import classes from "./DropDown.module.css";
import downChevron from "../../assets/down.png";
import { useClickOutside } from "../hooks/useClickOutside";

const DropDown = ({ onSearch,setSearch,onSelect,setSelected,optionVal, getVal, optionArr, optionOutput, label }) => {
 
  const [showOptions, setShowOptions] = useState(false);
  const [isSelectedVal, setIsSelectedVal] = useState(false);
  const [searchedArr, setSearchedArr] = useState(optionArr);
  const dropDownRef = useRef(null);

  useClickOutside(dropDownRef, () => setShowOptions(false), true);

  const searchChangeHandler = (e) => {
    setSearch(e.target.value);
    setShowOptions(true);
  };
  useEffect(() => {
    const filterArr = optionArr?.filter((n) => {
      return n[optionOutput].toLowerCase()?.includes(onSearch.toLowerCase());
    });
    setSearchedArr(filterArr);
  }, [onSearch, optionArr]);

  return (
    <div ref={dropDownRef} className={classes["inp-box"]}>
      <label className={classes.label}>{label}</label>
      <sup>*</sup>
      <div>
        <input
          required
          className={classes.inp}
          placeholder="Select..."
          onChange={searchChangeHandler}
          value={isSelectedVal ? onSelect : onSearch}
          onClick={() => {
            setIsSelectedVal(false);
            setSearch("");
            setIsSelectedVal("")
            getVal("")
            setShowOptions((prev) => !prev);
          }}
        />
        <button
          type="button"
          className={classes["down-btn"]}
          onClick={() => setShowOptions((prev) => !prev)}
        >
          <img src={downChevron} />
        </button>
        {showOptions && (
          <div className={classes["option-container"]}>
            {searchedArr.length === 0 ? (
              <option className={classes.option}> No Options</option>
            ) : (
              <>
                {searchedArr.map((n) => {
                  return (
                    <div
                      className={classes.option}
                      onClick={() => {
                        setSelected(n[optionOutput]);
                        getVal(n[optionVal]);
                        setIsSelectedVal(true);
                        setShowOptions(false);
                      }}
                    >
                      {n[optionOutput]}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDown;
