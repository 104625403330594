import React, { useState, useEffect } from "react";
import classes from "./ViewHosting.module.css";
import SericalIcon from "../../../../assets/customer-view-asstes/serial.png";
import DomainIcon from "../../../../assets/customer-view-asstes/country.png";
import CalendarIcon from "../../../../assets/customer-view-asstes/calendar.png";
import VendorIcon from "../../../../assets/customer-view-asstes/vendor.png";
import HostingTypeIcon from "../../../../assets/customer-view-asstes/hosting.png";
import ActionIcon from "../../../../assets/customer-view-asstes/action-icon.png";
import { formatDate } from "../../../../helpers";
import axios from "axios";

// Component

import EditModal from "../../../../shared/modal/EditModal";
import EditBtn from "../../../../shared/buttons/editButton/EditBtn";
import DeleteBtn from "../../../../shared/buttons/deleteButton/DeleteBtn";
import FormStructure from "../../../../shared/form/FormStructure";
import Input from "../../../../shared/inputs/Input";
import SmallBox from "../../../../shared/structures/SmallBox";

const ViewHosting = ({ code }) => {
  const [hostingData, setHostingData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [masterHostingData, setMasterHostingData] = useState([]);
  const [allInpVal, setAllInpVal] = useState({
    id:'',
    domain_name: "",
    vendor: "--select--",
    hosting_type: "--select--",
    frequency: "--select--",
    date_from: "",
    date_to: "dd-mm-yyyy",
  });

  const hostingChangeHandler = (e) => {
    setAllInpVal({
      ...allInpVal,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const fetchMasterHostingData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/masterhosting`
        );
        setMasterHostingData(res.data);
      } catch (error) {
        alert(error);
      }
    };
    fetchMasterHostingData();
  }, []);

  useEffect(() => {
    const fetchHostingData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/service/hosting/${code}`
      );
      setHostingData(res.data);
      console.log(res.data);
    };
    fetchHostingData();
  }, [reFetch]);

  const addHostingServiceHandler = (data) => {
    console.log(data.hosting_type);
    setAllInpVal({
      id:data.id,
      domain_name: data.domain_name,
      vendor: data.vendor,
      hosting_type: data.hosting_type,
      frequency: data.frequency,
      date_from: data.date_from,
      date_to: data.date_to,
    });
    setModalShow(true);
  };

  const hostingSubmitHandler = async (e) => {
    e.preventDefault();
    if (
      allInpVal.domain_name.trim() == "" ||
      allInpVal.vendor == "--select--" ||
      allInpVal.hosting_type == "--select--" ||
      allInpVal.frequency == "--select--" ||
      allInpVal.date_from.trim() == "" ||
      allInpVal.date_to.trim() == "dd-mm-yyyy"
    ) {
      alert("Enter all the feild");
      return;
    }
    await axios.put(`${process.env.REACT_APP_BASE_URL}/service/hosting/update/${allInpVal.id}`, {
      domain_name: allInpVal.domain_name.toLowerCase(),
      vendor: allInpVal.vendor.toLowerCase(),
      hosting_type: allInpVal.hosting_type,
      frequency: allInpVal.frequency,
      date_from: allInpVal.date_from,
      date_to: allInpVal.date_to,
      code: code,
      service_type: "hosting",
    });
    setAllInpVal({
      domain_name: "",
      vendor: "--select--",
      hosting_type: "--select--",
      frequency: "--select--",
      date_from: "",
      date_to: "dd-mm-yyyy",
    });
    setModalShow(false);
    setReFetch(!reFetch);
  };

  const resetHandler = () => {
    setAllInpVal({
      domain_name: "",
      vendor: "--select--",
     hosting_type: "--select--",
      frequency: "--select--",
      date_from: "",
      date_to: "dd-mm-yyyy",
    });
  };

  const deleteHandler = () => {
    setReFetch(!reFetch);
  };

  useEffect(() => {
    if (allInpVal.date_from !== "" && allInpVal.frequency !== "") {
      let date = new Date(allInpVal.date_from);
      let numOfMonths = 0;
      if (allInpVal.frequency === "monthly") {
        numOfMonths = 1;
      } else if (allInpVal.frequency === "quarter") {
        numOfMonths = 3;
      } else if (allInpVal.frequency === "six-months") {
        numOfMonths = 6;
      } else if (allInpVal.frequency === "yearly") {
        numOfMonths = 12;
      }
      date.setMonth(date.getMonth() + numOfMonths);
      setAllInpVal({
        ...allInpVal,
        date_to: formatDate(date),
      });
    }
  }, [allInpVal.date_from, allInpVal.frequency]);

  const astric = <sup>*</sup>;

  return (
    <>
      {hostingData.length === 0 ? (
        <div className={classes.empty}>
          Hosting Service not purchased yet!!!
        </div>
      ) : (
        <div className={classes["table"]}>
          <div className={classes["header"]}>
            <div>
              <img src={SericalIcon} alt="icon" />
              <span>Sr.No</span>
            </div>
            <div>
              <img src={DomainIcon} alt="icon" />
              <span>Domain</span>
            </div>
            <div>
              <img src={VendorIcon} alt="icon" />
              <span>Vendor</span>
            </div>
            <div>
              <img src={HostingTypeIcon} alt="icon" />
              <span>Type</span>
            </div>
            <div>
              <img src={CalendarIcon} alt="icon" />
              <span>D.O.Purchase</span>
            </div>
            <div>
              <img src={CalendarIcon} alt="icon" />
              <span>D.O.Expiry</span>
            </div>
            <div>
              <img src={ActionIcon} alt="action-icon" />
              <span>Action</span>
            </div>
          </div>
          <div className={classes["body"]}>
            {hostingData.map((data, i) => (
              <div key={i} className={classes["tr"]}>
                <div>
                  <span>{i + 1}</span>
                </div>
                <div>
                  <span>{data.domain_name}</span>
                </div>
                <div>
                  <span>{data.vendor}</span>
                </div>
                <div>
                  <span>{data.hosting_type}</span>
                </div>
                <div>
                  <span>{data.date_from}</span>
                </div>
                <div>
                  <span>{data.date_to}</span>
                </div>
                <div>
                  <span className={classes["action-container"]}>
                    <EditBtn onClick={() => addHostingServiceHandler(data)} />
                    <DeleteBtn
                      ondelete={deleteHandler}
                      id={data.id}
                      masterRoutes={"service/hosting"}
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>
          {modalShow && (
            <EditModal
              show={modalShow}
              title={"Service Hosting Edit"}
              onHide={() => {
                setModalShow(false);
              }}
            >
              <FormStructure
                onSubmit={hostingSubmitHandler}
                onreset={resetHandler}
                btn1Title="Reset"
                btn2Title="Save"
              >
                <SmallBox>
                  <Input
                    label="Domain"
                    type="text"
                    name="domain_name"
                    placeholder="Enter Domain"
                    onChange={hostingChangeHandler}
                    required="required"
                    value={allInpVal.domain_name}
                    astric={astric}
                  />
                  <div className={classes["inp-box"]}>
                    <label className={classes.label}>Vendor</label>
                    <sup>*</sup>
                    <select
                      name="vendor"
                      value={allInpVal.vendor}
                      onChange={hostingChangeHandler}
                      className={classes["drop-down"]}
                      required
                    >
                      <option value="--select--" selected>
                        --select--
                      </option>
                      {masterHostingData.map((data) => {
                        return (
                          <option key={data.id} value={data.vendor}>
                            {data.vendor}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </SmallBox>
                <SmallBox>
                  <div className={classes["inp-box"]}>
                    <label className={classes.label}>Hosting Type</label>
                    <sup>*</sup>
                    <select
                      name="hosting_type"
                      value={allInpVal.hosting_type}
                      onChange={hostingChangeHandler}
                      className={classes["drop-down"]}
                      required
                    >
                      <option value="--select--" selected>
                        --select--
                      </option>
                      {masterHostingData.map((data) => {
                        return (
                          <option key={data.id} value={data.hosting_type}>
                            {data.hosting_type}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className={classes["inp-box"]}>
                    <label className={classes.label}>Frequency</label>
                    <sup>*</sup>
                    <select
                      name="frequency"
                      value={allInpVal.frequency}
                      onChange={hostingChangeHandler}
                      className={classes["drop-down"]}
                      required
                    >
                      <option value="--select--" selected>
                        --select--
                      </option>
                      <option value="monthly">Monthly</option>
                      <option value="quarter">Quarterly</option>
                      <option value="six-months">Six-month</option>
                      <option value="yearly">Yearly</option>
                    </select>
                  </div>
                </SmallBox>
                <SmallBox>
                  <div className={classes["inp-box"]}>
                    <label className={classes.label}>Date From</label>
                    <sup>*</sup>
                    <input
                      className={classes["drop-down"]}
                      type="date"
                      name="date_from"
                      onChange={hostingChangeHandler}
                      required="required"
                      value={allInpVal.date_from}
                      astric={astric}
                    />
                  </div>
                  <div className={classes["inp-box"]}>
                    <label className={classes.label}>Date to</label>
                    <sup>*</sup>
                    <input
                      className={classes["drop-down"]}
                      type="text"
                      name="date_to"
                      readOnly
                      onChange={hostingChangeHandler}
                      required="required"
                      value={allInpVal.date_to}
                      astric={astric}
                    />
                  </div>
                </SmallBox>
              </FormStructure>
            </EditModal>
          )}
        </div>
      )}
    </>
  );
};

export default ViewHosting;
