import React, { useState, useEffect } from "react";
import classes from "./HostingForm.module.css";
import axios from "axios";
import Toast from "../../toast/toast";
import { toast } from "react-toastify";

//COMPONENTS
import SmallBox from "../../../shared/structures/SmallBox";
import Input from "../../../shared/inputs/Input";
import FormStructure from "../../../shared/form/FormStructure";
import { formatDate } from "../../../helpers";

const HostingForm = ({ code, serviceType }) => {
  const [hostingData, setHostingData] = useState([]);
  const [allInpVal, setAllInpVal] = useState({
    domain_name: "",
    vendor: "--select--",
    type: "--select",
    frequency: "--select--",
    date_from: "",
    date_to: "dd-mm-yyyy",
  });
  const hostingChangeHandler = (e) => {
    setAllInpVal({
      ...allInpVal,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const fetchHostingData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/masterhosting`
        );
        setHostingData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchHostingData();
  }, []);

  const hostingSubmitHandler = async (e) => {
    e.preventDefault();
    if (
      allInpVal.domain_name.trim() == "" ||
      allInpVal.vendor == "--select--" ||
      allInpVal.type == "--select--" ||
      allInpVal.frequency == "--select--" ||
      allInpVal.date_from.trim() == "" ||
      allInpVal.date_to.trim() == "dd-mm-yyyy"
    ) {
      alert("Enter all the feild");
      return;
    }
    await fetch(`${process.env.REACT_APP_BASE_URL}/service/hosting`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        domain_name: allInpVal.domain_name.toLowerCase(),
        vendor: allInpVal.vendor.toLowerCase(),
        hosting_type: allInpVal.type.toLowerCase(),
        frequency: allInpVal.frequency.toLowerCase(),
        date_from: allInpVal.date_from,
        date_to: allInpVal.date_to,
        code: code,
        service_type: serviceType.toLowerCase(),
      }),
    });
    toast.success("Hosting service added successfully.");
    setAllInpVal({
      domain_name: "",
      vendor: "--select--",
      type: "--select--",
      frequency: "--select--",
      date_from: "",
      date_to: "dd-mm-yyyy",
    });
  };
  const resetHandler = () => {
    setAllInpVal({
      domain_name: "",
      vendor: "--select--",
      type: "--select--",
      frequency: "--select--",
      date_from: "",
      date_to: "dd-mm-yyyy",
    });
  };

  useEffect(() => {
    if (allInpVal.date_from !== "" && allInpVal.frequency !== "--select--") {
      let date = new Date(allInpVal.date_from);
      let numOfMonths = 0;
      if (allInpVal.frequency === "monthly") {
        numOfMonths = 1;
      } else if (allInpVal.frequency === "quarter") {
        numOfMonths = 3;
      } else if (allInpVal.frequency === "six-months") {
        numOfMonths = 6;
      } else if (allInpVal.frequency === "yearly") {
        numOfMonths = 12;
      }
      date.setMonth(date.getMonth() + numOfMonths);
      setAllInpVal({
        ...allInpVal,
        date_to: formatDate(date),
      });
    }
  }, [allInpVal.date_from, allInpVal.frequency]);

  const astric = <sup>*</sup>;
  return (
    <FormStructure
      onSubmit={hostingSubmitHandler}
      onreset={resetHandler}
      btn1Title="Reset"
      btn2Title="Save"
    >
      <SmallBox>
        <Input
          label="Domain"
          type="text"
          name="domain_name"
          placeholder="Enter Domain"
          onChange={hostingChangeHandler}
          required="required"
          value={allInpVal.domain_name}
          astric={astric}
        />
        <div className={classes["inp-box"]}>
          <label className={classes.label}>Vendor</label>
          <sup>*</sup>
          <select
            name="vendor"
            value={allInpVal.vendor}
            onChange={hostingChangeHandler}
            className={classes["drop-down"]}
            required
          >
            <option value="--select--" selected>
              --select--
            </option>
            {hostingData.map((data) => {
              return (
                <option key={data.id} value={data.vendor}>
                  {data.vendor}
                </option>
              );
            })}
          </select>
        </div>
      </SmallBox>
      <SmallBox>
        <div className={classes["inp-box"]}>
          <label className={classes.label}>Type</label>
          <sup>*</sup>
          <select
            name="type"
            value={allInpVal.type}
            onChange={hostingChangeHandler}
            className={classes["drop-down"]}
            required
          >
            <option value="--select--" selected>
              --select--
            </option>
            {hostingData.map((data) => {
              return (
                <option key={data.id} value={data.hosting_type}>
                  {data.hosting_type}
                </option>
              );
            })}
          </select>
        </div>
        <div className={classes["inp-box"]}>
          <label className={classes.label}>Frequency</label>
          <sup>*</sup>
          <select
            name="frequency"
            value={allInpVal.frequency}
            onChange={hostingChangeHandler}
            className={classes["drop-down"]}
            required
          >
            <option value="--select--" selected>
              --select--
            </option>
            <option value="monthly">Monthly</option>
            <option value="quarter">Quarterly</option>
            <option value="six-months">Six-month</option>
            <option value="yearly">Yearly</option>
          </select>
        </div>
      </SmallBox>
      <SmallBox>
        <div className={classes["inp-box"]}>
          <label className={classes.label}>Date From</label>
          <sup>*</sup>
          <input
            className={classes["drop-down"]}
            type="date"
            name="date_from"
            onChange={hostingChangeHandler}
            required="required"
            value={allInpVal.date_from}
            astric={astric}
          />
        </div>
        <div className={classes["inp-box"]}>
          <label className={classes.label}>Date to</label>
          <sup>*</sup>
          <input
            className={classes["drop-down"]}
            type="text"
            name="date_to"
            readOnly
            onChange={hostingChangeHandler}
            required="required"
            value={allInpVal.date_to}
            astric={astric}
          />
        </div>
      </SmallBox>
      <Toast />
    </FormStructure>
  );
};

export default HostingForm;
