import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import "./Sidebar.css";
import AcairaLogo from "../../assets/new-logo.png";
import MainLogo from "../../assets/logo.png";
import { Outlet } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Sidebar = () => {
  const navigate = useNavigate();
  const [isSideBarClose, setIsSideBarClose] = useState(false);
  const [dropDownOne, setDropDownOne] = useState(false);
  const [dropDownTwo, setDropDownTwo] = useState(false);
  const [dropDownThree, setDropDownThree] = useState(false);
  const [dropDownFour, setDropDownFour] = useState(false);

  const dropDownOneHandler = () => {
    setDropDownOne(!dropDownOne);
    setDropDownTwo(false);
    setDropDownThree(false);
    setDropDownFour(false);
  };

  const dropDownTwoHandler = () => {
    setDropDownOne(false);
    setDropDownTwo(!dropDownTwo);
    setDropDownThree(false);
    setDropDownFour(false);
  };

  const dropDownThreeHandler = () => {
    setDropDownOne(false);
    setDropDownTwo(false);
    setDropDownThree(!dropDownThree);
    setDropDownFour(false);
  };
  const dropDownFourHandler = () => {
    setDropDownOne(false);
    setDropDownTwo(false);
    setDropDownThree(false);
    setDropDownFour(!dropDownFour);
  };
  const sideBarHandler = () => {
    setIsSideBarClose(!isSideBarClose);
    setDropDownOne(false);
    setDropDownTwo(false);
    setDropDownFour(false);
  };

  const username = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <div className={`sidebar ${isSideBarClose ? "close" : ""}`}>
        <div className="logo-details">
          <i className="bx">
            {isSideBarClose && (
              <Link to="/">
                <img className="a-logo" src={AcairaLogo} />
              </Link>
            )}
            {!isSideBarClose && (
              <Link to="/">
                <img className="main-logo" src={MainLogo} />
              </Link>
            )}
          </i>
        </div>
        <ul className="nav-links">
          <li>
            <a>
              <i className="bx bx-pie-chart-alt-2"></i>
              <span className="link_name">My Company</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name">My Company</a>
              </li>
            </ul>
          </li>
          {username.role === "admin" && (
            <li
              onClick={dropDownOneHandler}
              className={`${dropDownOne ? "showMenu" : ""}`}
            >
              <div className="iocn-link">
                <a>
                  <i className="bx bx-user-pin"></i>
                  <span className="link_name">User</span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu">
                <li>
                  <a className="link_name">User</a>
                </li>
                <li>
                  <Link to="/adduser">Add User</Link>
                </li>
                <li>
                  <Link to="/userlist">User List</Link>
                </li>
              </ul>
            </li>
          )}
          <li
            onClick={dropDownTwoHandler}
            className={`${dropDownTwo ? "showMenu" : ""}`}
          >
            <div className="iocn-link">
              <a>
                <i className="bx bx-book-alt"></i>
                <span className="link_name">Customer</span>
              </a>
              <i className="bx bxs-chevron-down arrow"></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name">Customer</a>
              </li>
              <li>
                <Link to="/addcustomer"> Add Customer</Link>
              </li>
              <li>
                <Link to="/customerlist">Customer List</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="services">
              <i className="bx bx-plug"></i>
              <span className="link_name">Services</span>
            </Link>
            <ul className="sub-menu blank">
              <li>
                <Link className="link_name" to="services">
                  Services
                </Link>
              </li>
            </ul>
          </li>

          <li
            onClick={dropDownThreeHandler}
            className={`${dropDownThree ? "showMenu" : ""}`}
          >
            <div className="iocn-link">
              <a>
                <i className="bx bxs-layout"></i>
                <span className="link_name">Project</span>
              </a>
              <i className="bx bxs-chevron-down arrow"></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name">Project</a>
              </li>
              <li>
                <Link to="/addproject"> Add Project</Link>
              </li>
              <li>
                <Link to="/addmodule"> Add Module</Link>
              </li>
              <li>
                <Link to="/projectlist"> Project List</Link>
              </li>
            </ul>
          </li>

          <li
            onClick={dropDownFourHandler}
            className={`${dropDownFour ? "showMenu" : ""}`}
          >
            <div className="iocn-link">
              <a>
                <i className="bx bx-collection"></i>
                <span className="link_name">Master</span>
              </a>
              <i className="bx bxs-chevron-down arrow"></i>
            </div>
            <ul className="sub-menu">
              <li>
                <Link className="link_name">Master</Link>
              </li>
              <li>
                <Link to="/masterdomain">Domain</Link>
              </li>
              <li>
                <Link to="/masterhosting">Hosting</Link>
              </li>
              <li>
                <Link to="/masteremail">Email</Link>
              </li>
              <li>
                <Link to="/masterssl">SSL</Link>
              </li>
              <li>
                <Link to="/masterseo">SEO</Link>
              </li>
              <li>
                <Link to="/masterprojecttype">Project Type</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <section className="home-section">
        <div className="header">
          <div className="home-content">
            <i onClick={sideBarHandler} className="bx bx-menu"></i>
          </div>

          <Menu as="div" className="relative">
            <Menu.Button className="mr-2 flex items-center">
              <span className="sr-only">Open user menu</span>
              <img
                className="h-8 w-8 rounded-full bg-gray-50"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
              <span className="hidden lg:flex lg:items-center">
                <span
                  className="ml-4 font-sans text-sm font-semibold leading-6 text-gray-900"
                  aria-hidden="true"
                >
                  {username.username.toUpperCase()}
                </span>
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-3 z-10 mt-2.5 w-36 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <Menu.Item>
                  <Link className="block px-3 py-1 text-sm leading-6 text-gray-900">
                    {username.username.toUpperCase()}
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    className="block px-3 py-1 text-sm leading-6 text-gray-900"
                    to="/changepassword"
                  >
                    Change Password
                  </Link>
                </Menu.Item>

                <Menu.Item>
                  <Link
                    onClick={() => {
                      localStorage.clear();
                      navigate("/login");
                    }}
                    className={
                      "block px-3 py-1 hover:text-blue-800 text-sm leading-6 text-gray-900"
                    }
                  >
                    Logout
                  </Link>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
          {/* <Dropdown className="nav-dropdown">
            <Dropdown.Toggle variant="light" id="dropdown-basic">
              <img
                className="dropdown-logo"
                src={MainLogo}
                alt="company-logo"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>{username.username.toUpperCase()}</Dropdown.Item>
              <Link to="/changepassword">Change Password</Link>
              <Dropdown.Item
                onClick={() => {
                  localStorage.clear();
                  window.location.replace("/login");
                }}
              >
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
        <Outlet />
      </section>
    </>
  );
};

export default Sidebar;
