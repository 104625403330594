import React, { useState, useEffect } from "react";
import classes from "./AddModule.module.css";
import Box from "../../../shared/structures/Box";
import FormStructure from "../../../shared/form/FormStructure";
import SmallBox from "../../../shared/structures/SmallBox";
import Input from "../../../shared/inputs/Input";
import axios from "axios";
import Toast from "../../toast/toast"
import { toast } from "react-toastify";
import DropDown from "../../../shared/dropdown/DropDown";

const AddModule = () => {
  const [code, setCode] = useState();
  const [projectId, setProjectId] = useState("");
  const [customerNameArr, setCustomerNameArr] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [custSearchVal, setCustSearchVal] = useState("");
  const [projectSearchVal, setProjectSearchVal] = useState("");
  const [allModuleVal, setAllModuleVal] = useState({
    module_name: "",
    module_description: "",
    amount: "",
    start_date: "",
    end_date: "",
  });

  const moduleInpChangeHandler = (e) => {
    setAllModuleVal({
      ...allModuleVal,
      [e.target.name]: e.target.value,
    });
  };

  const getSelectedCustomerName = (value) => {
    setCode(value);
  };
  const getSelectedProjectName = (value) => {
    setProjectId(value);
  };
  useEffect(() => {
    const fetchCustomerName = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/customer`
        );
        setCustomerNameArr(res.data);
      } catch (error) {
        throw new Error("something went wrong", error);
      }
    };
    fetchCustomerName();
  }, []);

  useEffect(() => {
    const fetchProjectName = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/projectview/${code}`
        );
        setProjectName(res.data);
      } catch (error) {
        throw new Error("something went wrong", error);
      }
    };
    fetchProjectName();
  }, [code]);

  const submitModuleHandler = async (e) => {
    if (
      projectId !== "" ||
      code !== "" ||
      allModuleVal.module_name.trim() !== "" ||
      allModuleVal.amount.trim() !== "" ||
      allModuleVal.start_date.trim() !== "" ||
      allModuleVal.end_date.trim() !== "" ||
      allModuleVal.module_description.trim() !== ""
    ) {
      e.preventDefault();
      await axios.post(`${process.env.REACT_APP_BASE_URL}/projectmodule`, {
        project_id: projectId,
        amount: allModuleVal.amount,
        module_name: allModuleVal.module_name,
        start_date: allModuleVal.start_date,
        end_date: allModuleVal.end_date,
        module_description: allModuleVal.module_description,
      });
      setAllModuleVal({
        amount: "",
        module_name: "",
        module_description: "",
        start_date: "",
        end_date: "",
      });
toast.success("Module added successfully.")
      setCode("");
      setProjectId("");
      setSelectedCustomerName("");
      setSelectedProject("");
      setCustSearchVal("");
      setProjectSearchVal("");

    } else {
      e.preventDefault();
      toast.warning("Please enter all the fields.");
    }
  };
  const resetHandler = () => {
    setAllModuleVal({
      module_name: "",
      module_description: "",
      amount: "",
      start_date: "",
      end_date: "",
    });
    setSelectedProject(null);
    setSelectedCustomerName("");
  };
  const astric = <sup>*</sup>;
  return (
    <Box
      iconName="pencil-outline"
      boxTitle="Add Module"
      info="Add Module Details"
      parentBreadCrumb="dashboard"
    >
      <FormStructure
        onSubmit={submitModuleHandler}
        onreset={resetHandler}
        btn1Title="Reset"
        btn2Title="Save"
      >
        <SmallBox>
          <DropDown
            label="Customer Name"
            optionOutput={"customer_name"}
            optionVal={"code"}
            optionArr={customerNameArr}
            getVal={getSelectedCustomerName}
            onSearch={custSearchVal}
            setSearch={setCustSearchVal}
            onSelect={selectedCustomerName}
            setSelected={setSelectedCustomerName}
          />
          <DropDown
            label="project Name"
            optionOutput={"project_name"}
            optionVal={"project_id"}
            optionArr={projectName}
            getVal={getSelectedProjectName}
            onSearch={projectSearchVal}
            setSearch={setProjectSearchVal}
            onSelect={selectedProject}
            setSelected={setSelectedProject}
          />
        </SmallBox>
        <SmallBox>
          <Input
            label="Module Name"
            type="text"
            name="module_name"
            placeholder="Enter Module Name"
            onChange={moduleInpChangeHandler}
            value={allModuleVal.module_name}
            required="required"
            astric={astric}
          />
          <Input
            label="Amount"
            type="number"
            name="amount"
            placeholder="Enter Amount"
            onChange={moduleInpChangeHandler}
            value={allModuleVal.amount}
            required="required"
            astric={astric}
          />
        </SmallBox>

        <SmallBox>
          <div className={classes["inp-box"]}>
            <label className={classes.label}>Date From</label>
            <sup>*</sup>
            <input
              className={classes["drop-down"]}
              type="date"
              name="start_date"
              onChange={moduleInpChangeHandler}
              required="required"
              value={allModuleVal.start_date}
              astric={astric}
            />
          </div>
          <div className={classes["inp-box"]}>
            <label className={classes.label}>Date To</label>
            <sup>*</sup>
            <input
              className={classes["drop-down"]}
              type="date"
              name="end_date"
              onChange={moduleInpChangeHandler}
              required="required"
              value={allModuleVal.end_date}
              astric={astric}
            />
          </div>
        </SmallBox>
        <SmallBox>
          <div className={classes["text-box"]}>
            <label className={classes["text-area-label"]}>
              Module Description
            </label>
            <sup>*</sup>
            <textarea
              name="module_description"
              className={classes["text-area"]}
              placeholder="Enter Module Description"
              onChange={moduleInpChangeHandler}
              value={allModuleVal.module_description}
              rows="3"
              required
            />
          </div>
        </SmallBox>
      </FormStructure>
      <Toast/>
    </Box>
  );
};

export default AddModule;
