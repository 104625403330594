import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import React, { useState } from "react";
import { checkAuthLoader } from "./utils/auth";

// pages
import Main from "./pages/Main";
import Login from "./pages/Login";

// components
import UserList from "../src/components/users/userlist/UserList";
import UserForm from "../src/components/users/userform/UserForm";
import CustomerForm from "../src/components/customer/customerForm/CustomerForm";
import MasterDomainForm from "../src/components/master/masterdomain/MasterDomainForm";
import MasterHostingForm from "../src/components/master/masterhosting/MasterHostingForm";
import MasterEmailForm from "../src/components/master/masteremail/MasterEmailForm";
import MasterSslForm from "../src/components/master/masterssl/MasterSslForm";
import MasterProjectTypeForm from "../src/components/master/masterprojecttype/MasterProjectTypeForm";
import MasterSeoForm from "../src/components/master/masterseo/MasterSeoForm";
import Services from "../src/components/services/Services";
import CustomerList from "../src/components/customer/customerList/CustomerList";
import Customerview from "./components/customer/customerview/CustomerView";
import Dashboard from "./pages/dashboard/Dashboard";
import ChangePassword from "./components/changePassword/ChangePassword";
import AddProject from "./components/project/addProject/AddProject";
import ProjectList from "./components/project/projectList/ProjectList";
import AddModule from "./components/project/addProjectModule/AddModule";
import ViewProject from "./components/project/viewProject/ViewProject";
import CustomerEdit from "./components/customer/customerEdit/CustomerEdit";

function App() {
  const userDetail = JSON.parse(localStorage.getItem("user"));

  const router = createBrowserRouter([
    { path: "/login", element: <Login /> },
    {
      path: "/",
      element: <Main />,
      loader: checkAuthLoader,
      children: [
        {
          path: "/",
          element: <Dashboard />,
          loader: checkAuthLoader,
        },
        userDetail?.role === "admin" && {
          path: "/adduser",
          element: <UserForm />,
          loader: checkAuthLoader,
        },
        userDetail?.role === "admin" && {
          path: "/userlist",
          element: <UserList />,
          loader: checkAuthLoader,
        },
        {
          path: "/services",
          element: <Services />,
          loader: checkAuthLoader,
        },
        {
          path: "/addcustomer",
          element: <CustomerForm />,
          loader: checkAuthLoader,
        },
        {
          path: "/customerlist",
          element: <CustomerList />,
          loader: checkAuthLoader,
        },
        {
          path: "/customerlist/customerview",
          element: <Customerview />,
          loader: checkAuthLoader,
        },
        {
          path: "/customerlist/customerview/customeredit",
          element: <CustomerEdit />,
          loader: checkAuthLoader,
        },
        {
          path: "/addproject",
          element: <AddProject />,
          loader: checkAuthLoader,
        },
        {
          path: "/addmodule",
          element: <AddModule />,
          loader: checkAuthLoader,
        },
        {
          path: "/projectlist",
          element: <ProjectList />,
          loader: checkAuthLoader,
        },
        {
          path: "/projectlist/viewproject",
          element: <ViewProject />,
          loader: checkAuthLoader,
        },
        {
          path: "/masterdomain",
          element: <MasterDomainForm />,
          loader: checkAuthLoader,
        },
        {
          path: "/masterhosting",
          element: <MasterHostingForm />,
          loader: checkAuthLoader,
        },
        {
          path: "/masterssl",
          element: <MasterSslForm />,
          loader: checkAuthLoader,
        },
        {
          path: "/masteremail",
          element: <MasterEmailForm />,
          loader: checkAuthLoader,
        },
        {
          path: "/masterseo",
          element: <MasterSeoForm />,
          loader: checkAuthLoader,
        },
        {
          path: "/masterprojecttype",
          element: <MasterProjectTypeForm />,
          loader: checkAuthLoader,
        },
        {
          path: "/changepassword",
          element: <ChangePassword />,
          loader: checkAuthLoader,
        },
      ],
    },
  ]);

  return (
    <div className="app">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
