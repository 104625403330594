import React, { useState, useEffect } from "react";
import classes from "./ViewEmail.module.css";
import { formatDate, formatFullDate } from "../../../../helpers";
import axios from "axios";
import CalendarIcon from "../../../../assets/customer-view-asstes/calendar.png";
import DomainIcon from "../../../../assets/customer-view-asstes/country.png";
import VendorIcon from "../../../../assets/customer-view-asstes/vendor.png";
import EmailIcon from "../../../../assets/customer-view-asstes/no-of-email.png";
import SerialIcon from "../../../../assets/customer-view-asstes/serial.png";
import ActionIcon from "../../../../assets/customer-view-asstes/action-icon.png";
import EditBtn from "../../../../shared/buttons/editButton/EditBtn";
import DeleteBtn from "../../../../shared/buttons/deleteButton/DeleteBtn";
import EditModal from "../../../../shared/modal/EditModal";
import FormStructure from "../../../../shared/form/FormStructure";
import Input from "../../../../shared/inputs/Input";
import SmallBox from "../../../../shared/structures/SmallBox";

const ViewEmail = ({ code }) => {
  const [emailData, setEmailData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [allInpVal, setAllInpVal] = useState({
    id: "",
    domain_name: "",
    vendor: "--select--",
    no_of_years: "--select--",
    no_of_email: "",
    date_from: "",
    date_to: "dd-mm-yyyy",
  });

  const emailChangeHandler = (e) => {
    setAllInpVal({
      ...allInpVal,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const fetchEmailData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/service/email/${code}`
      );
      setEmailData(res.data);
    };
    fetchEmailData();
  }, [reFetch]);

  const emailSubmitHandler = async (e) => {
    e.preventDefault();
    if (
      allInpVal.domain_name.trim() === "" ||
      allInpVal.vendor === "--select--" ||
      allInpVal.no_of_years === "--select--" ||
      allInpVal.no_of_email === "" ||
      allInpVal.date_from.trim() === "" ||
      allInpVal.date_to.trim() === "dd-mm-yyyy"
    ) {
      alert("Please enter all the field");
      return;
    }
    await axios.put(
      `${process.env.REACT_APP_BASE_URL}/service/email/update/${allInpVal.id}`,
      {
        domain_name: allInpVal.domain_name.toLowerCase(),
        vendor: allInpVal.vendor.toLowerCase(),
        no_of_years: allInpVal.no_of_years,
        no_of_email: allInpVal.no_of_email,
        date_from: allInpVal.date_from,
        date_to: allInpVal.date_to,
        code: code,
      }
    );
    setAllInpVal({
      domain_name: "",
      vendor: "--select--",
      no_of_years: "--select--",
      no_of_email: "",
      date_from: "",
      date_to: "dd-mm-yyyy",
    });
    setReFetch(!reFetch);
    setModalShow(false);
  };
  const resetHandler = () => {
    setAllInpVal({
      domain_name: "",
      vendor: "--select--",
      no_of_years: "--select--",
      no_of_email: "",
      date_from: "",
      date_to: "dd-mm-yyyy",
    });
  };

  const deleteHandler = () => {
    setReFetch(!reFetch);
  };
  const addEmailServiceHandler = (data) => {
    setAllInpVal({
      id: data.id,
      domain_name: data.domain_name,
      vendor: data.vendor,
      no_of_years: data.no_of_years,
      no_of_email: data.no_of_email,
      date_from: data.date_from,
      date_to:data.date_to,
    });
    setModalShow(true);
  };

  useEffect(() => {
    if (allInpVal.date_from !== "" && allInpVal.no_of_years !== "--select--") {
      let date = new Date(allInpVal.date_from);
      date.setFullYear(date.getFullYear() + parseInt(allInpVal.no_of_years));
      setAllInpVal({
        ...allInpVal,
        date_to: formatDate(date),
      });
    }
  }, [allInpVal.date_from, allInpVal.no_of_years]);

  const astric = <sup>*</sup>;

  return (
    <>
      {emailData.length === 0 ? (
        <div className={classes.empty}>Email Service not purchased yet!!!</div>
      ) : (
        <div className={classes["table"]}>
          <div className={classes["header"]}>
            <div>
              <img src={SerialIcon} alt />
              <span>Sr.No</span>
            </div>
            <div>
              <img src={EmailIcon} alt />
              <span>No Of Email</span>
            </div>
            <div>
              <img src={DomainIcon} alt />
              <span>Domain</span>
            </div>
            <div>
              <img src={VendorIcon} alt />
              <span>Vendor</span>
            </div>
            <div>
              <img src={CalendarIcon} alt />
              <span>D.O.Purchase</span>
            </div>
            <div>
              <img src={CalendarIcon} alt />
              <span>D.O.Expiry</span>
            </div>
            <div>
              <img src={ActionIcon} alt="action-icon" />
              <span>Action</span>
            </div>
          </div>
          <div className={classes["body"]}>
            {emailData.map((data, i) => (
              <div key={i} className={classes["tr"]}>
                <div>
                  <span>{i + 1}</span>
                </div>
                <div>
                  <span>{data.no_of_email}</span>
                </div>
                <div>
                  <span>{data.domain_name}</span>
                </div>
                <div>
                  <span>{data.vendor}</span>
                </div>
                <div>
                  <span>{data.date_from}</span>
                </div>
                <div>
                  <span>{data.date_to}</span>
                </div>
                <div>
                  <span className={classes["action-container"]}>
                    <EditBtn onClick={() => addEmailServiceHandler(data)} />
                    <DeleteBtn
                      ondelete={deleteHandler}
                      id={data.id}
                      masterRoutes={"service/domain"}
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>
          {modalShow && (
            <EditModal
              show={modalShow}
              title={"Email service edit"}
              onHide={() => {
                setModalShow(false);
              }}
            >
              <FormStructure
                onSubmit={emailSubmitHandler}
                onreset={resetHandler}
                btn1Title="Reset"
                btn2Title="Save"
              >
                <SmallBox>
                  <Input
                    label="Domain"
                    type="text"
                    name="domain_name"
                    placeholder="Enter Domain"
                    onChange={emailChangeHandler}
                    required="required"
                    value={allInpVal.domain_name}
                    astric={astric}
                  />
                  <div className={classes["inp-box"]}>
                    <label className={classes.label}>Vendor</label>
                    <sup>*</sup>
                    <select
                      name="vendor"
                      value={allInpVal.vendor}
                      onChange={emailChangeHandler}
                      className={classes["drop-down"]}
                      required
                    >
                      <option value="--select--" selected>
                        --select--
                      </option>
                      {emailData.map((data) => {
                        return (
                          <option key={data.id} value={data.vendor}>
                            {data.vendor}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </SmallBox>
                <SmallBox>
                  <div className={classes["inp-box"]}>
                    <label className={classes.label}>No of Years</label>
                    <sup>*</sup>
                    <select
                      value={allInpVal.no_of_years}
                      onChange={emailChangeHandler}
                      name="no_of_years"
                      className={classes["drop-down"]}
                      required
                    >
                      <option selected>--select--</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                  <Input
                    label="No of Email"
                    type="number"
                    name="no_of_email"
                    placeholder="Enter no of Email"
                    onChange={emailChangeHandler}
                    required="required"
                    value={allInpVal.no_of_email}
                    astric={astric}
                  />
                </SmallBox>
                <SmallBox>
                  <div className={classes["inp-box"]}>
                    <label className={classes.label}>Date From</label>
                    <sup>*</sup>
                    <input
                      className={classes["drop-down"]}
                      type="date"
                      name="date_from"
                      onChange={emailChangeHandler}
                      required="required"
                      value={allInpVal.date_from}
                      astric={astric}
                    />
                  </div>
                  <div className={classes["inp-box"]}>
                    <label className={classes.label}>Date to</label>
                    <sup>*</sup>
                    <input
                      className={classes["drop-down"]}
                      type="text"
                      name="date_to"
                      onChange={emailChangeHandler}
                      required="required"
                      value={allInpVal.date_to}
                      readOnly
                      astric={astric}
                    />
                  </div>
                </SmallBox>
              </FormStructure>
            </EditModal>
          )}
        </div>
      )}
    </>
  );
};

export default ViewEmail;
