import React, { useState, useEffect } from "react";
import classes from "./MasterSeoForm.module.css";
import axios from "axios";

// Component
import Box from "../../../shared/structures/Box";
import Form from "../../../shared/form/FormStructure";
import SmallBox from "../../../shared/structures/SmallBox";
import Input from "../../../shared/inputs/Input";
import DeleteBtn from "../../../shared/buttons/deleteButton/DeleteBtn";
import EditBtn from "../../../shared/buttons/editButton/EditBtn";
import EditModal from "../../../shared/modal/EditModal";
import { toast } from "react-toastify";
import Toast from "../../toast/toast";

const MasterSeoForm = () => {
  const [allMasterSeoVal, setAllMasterSeoVal] = useState({
    plan_name: "",
    price: "",
  });
  const [allMasterEditedSeo, setAllMasterEditedSeo] = useState({
    editplan_name: "",
    editPrice: "",
    id: "",
  });
  const [tableData, setTableData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  let i = 1;

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/masterseo`
        );
        setTableData(res.data);
      } catch (error) {
        throw new Error("something went wrong", error);
      }
    };
    fetchTableData();
  }, [reFetch]);

  const MasterSeoChangeHandler = (e) => {
    setAllMasterSeoVal({
      ...allMasterSeoVal,
      [e.target.name]: e.target.value,
    });
  };
  const masterEditSeoChangeHandler = (e) => {
    setAllMasterEditedSeo({
      ...allMasterEditedSeo,
      [e.target.name]: e.target.value,
    });
  };

  const submitMasterSeoFormHandler = async (e) => {
    e.preventDefault();
    await axios.post(`${process.env.REACT_APP_BASE_URL}/masterseo`, {
      plan_name: allMasterSeoVal.plan_name.toLowerCase(),
      price: allMasterSeoVal.price,
    });
    setAllMasterSeoVal({
      plan_name: "",
      price: "",
    });
    toast.success("SEO added successfully");
    setReFetch(!reFetch);
  };
  const updateHandler = async (e) => {
    e.preventDefault();
    await axios.put(
      `${process.env.REACT_APP_BASE_URL}/masterseo/update/${allMasterEditedSeo.id}`,
      {
        plan_name: allMasterEditedSeo.editplan_name.toLowerCase(),
        price: allMasterEditedSeo.editPrice,
      }
    );
    setModalShow(false);
    setReFetch(!reFetch);
    setAllMasterEditedSeo({
      editExtension: "",
      editPrice: "",
    });
    toast.success("SEO updated successfully");
  };

  const deleteHandler = () => {
    toast.error("SEO deleted successfully");
    setReFetch(!reFetch);
  };

  const editHandler = (data) => {
    setAllMasterEditedSeo({
      editplan_name: data.plan_name,
      editPrice: data.price,
      id: data.id,
    });
    setModalShow(true);
  };

  const resetHandler = () => {
    setAllMasterSeoVal({
      plan_name: "",
      price: "",
    });
  };
  const astric = <sup>*</sup>;

  return (
    <>
      <Box
        parentBreadCrumb="dashboard"
        iconName="pencil-outline"
        boxTitle="Add SEO"
        info="Master SEO Info"
      >
        <Form
          onSubmit={submitMasterSeoFormHandler}
          onreset={resetHandler}
          btn1Title="Reset"
          btn2Title="Save"
        >
          <SmallBox>
            <Input
              label="plan_name"
              type="text"
              name="plan_name"
              placeholder="Enter plan_name"
              onChange={MasterSeoChangeHandler}
              value={allMasterSeoVal.plan_name}
              required="required"
              astric={astric}
            />
            <Input
              label="Price"
              type="number"
              name="price"
              onChange={MasterSeoChangeHandler}
              value={allMasterSeoVal.price}
              placeholder="Enter Price"
              required="required"
              astric={astric}
            />
          </SmallBox>
        </Form>
      </Box>
      <table className={classes["table"]}>
        <thead className={classes.thead}>
          <tr>
            <th>Sr.no</th>
            <th>plan_name</th>
            <th>Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className={classes.tbody}>
          {tableData.length === 0 ? (
            <tr>
              <td className={classes["no-data"]} colSpan={4}>
                NO DATA
              </td>
            </tr>
          ) : (
            tableData.map((data) => (
              <tr className={classes.trow} key={data.id}>
                <td>{i++}</td>
                <td>{data.plan_name}</td>
                <td>
                  &#8377;
                  {data.price}
                </td>
                <td>
                  <EditBtn
                    onClick={() => {
                      editHandler(data);
                    }}
                  />
                  {modalShow && (
                    <EditModal
                      show={modalShow}
                      title={"Master SEO Edit"}
                      onHide={() => {
                        setModalShow(false);
                      }}
                    >
                      <Form
                        onSubmit={updateHandler}
                        onreset={resetHandler}
                        btn1Title="Reset"
                        btn2Title="Save"
                      >
                        <SmallBox>
                          <Input
                            label="plan_name"
                            type="text"
                            name="editplan_name"
                            placeholder="Enter plan_name Name"
                            onChange={masterEditSeoChangeHandler}
                            required="required"
                            value={allMasterEditedSeo.editplan_name}
                            astric={astric}
                          />
                          <Input
                            label="Price"
                            type="number"
                            name="editPrice"
                            onChange={masterEditSeoChangeHandler}
                            value={allMasterEditedSeo.editPrice}
                            placeholder="Enter Price"
                            required="required"
                            astric={astric}
                          />
                        </SmallBox>
                      </Form>
                    </EditModal>
                  )}
                  <DeleteBtn
                    id={data.id}
                    masterRoutes={"masterseo"}
                    ondelete={deleteHandler}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
        <Toast />
      </table>
    </>
  );
};

export default MasterSeoForm;
