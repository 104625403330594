import React, { useState, useEffect, useMemo } from "react";
import {
  usePagination,
  useSortBy,
  useTable,
  useGlobalFilter,
} from "react-table";
import { useNavigate } from "react-router-dom";
import ExpireGlobalFilter from "./ExpireGlobalFilter";
import axios from "axios";
import DownArrow from "../../assets/down.png";
import UpArrow from "../../assets/up.png";
import Box from "../../shared/structures/Box";
import classes from "./ExpireTable.module.css";

const ExpiryTable = ({ isServiceSelected }) => {
  const [selectedList, setSelectedList] = useState("Service List");
  const [services, setServices] = useState([]);
  const [project, setProject] = useState([]);
  const [expiredServices, setExpiredServices] = useState([]);
  const [expiredProjects, setExpiredProjects] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchServiceData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/dashboard/services`
      );
      setServices(res.data);
    };
    fetchServiceData();
  }, []);
  useEffect(() => {
    const fetchExpiredData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/dashboard/expired_services`
      );
      setExpiredServices(res.data);
    };
    fetchExpiredData();
  }, []);
  useEffect(() => {
    const fetchProjectData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/dashboard/project`
      );
      setProject(res.data);
    };
    fetchProjectData();
  }, []);
  useEffect(() => {
    const fetchExpiredProjectData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/dashboard/expired_project`
      );
      setExpiredProjects(res.data);
    };
    fetchExpiredProjectData();
  }, []);

  const serviceMemo = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "customer_name",
      },
      {
        Header: "Service Type",
        accessor: "service_type",
      },
      {
        Header: "Date Of Expiry",
        accessor: "date_to",
      },
      {
        Header: "Days Left",
        accessor: "day_left",
      },
    ],
    []
  );
  const expiredServiceMemo = useMemo(
    () => [
      {
        Header: "Code ",
        accessor: "code",
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
      },
      {
        Header: "Service Type",
        accessor: "service_type",
      },
      {
        Header: "Date Of Expiry",
        accessor: "date_to",
      },
    ],
    []
  );
  const projectMemo = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "customer_name",
      },
      {
        Header: "Project Name",
        accessor: "project_name",
      },
      {
        Header: "Date Of Expiry",
        accessor: "end_date",
      },
      {
        Header: "Days Left",
        accessor: "day_left",
      },
    ],
    []
  );
  const expiredProjectMemo = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "customer_name",
      },
      {
        Header: "Project Name",
        accessor: "project_name",
      },
      {
        Header: "Project Type",
        accessor: "project_type",
      },
      {
        Header: "Date Of Expiry",
        accessor: "end_date",
      },
    ],
    []
  );
  const column = isServiceSelected
    ? selectedList === "Expired Services"
      ? expiredServiceMemo
      : serviceMemo
    : selectedList === "Expired Projects"
    ? expiredProjectMemo
    : projectMemo;

  const Table = ({ columns, data }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      nextPage,
      previousPage,
      canNextPage,
      canPreviousPage,
      pageOptions,
      gotoPage,
      pageCount,
      prepareRow,
      state,
      setGlobalFilter,
    } = useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    const { globalFilter, pageIndex } = state;

    const dropdown = (
      <ExpireGlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
    );

    return (
      // <Box
      //   iconName="person-outline"
      //   boxTitle="Expiry list"
      //   info="Expiry list"
      //   dropdown={dropdown}
      // >

      <div className={classes.container}>
        <div className={classes.heading}>
          <h2>{selectedList}</h2>
          <span>
            {dropdown}
            <select
              value={selectedList}
              onChange={(e) => setSelectedList(e.target.value)}
            >
              {isServiceSelected ? (
                <>
                  <option value={"Service List"}>Services</option>
                  <option value={"Expired Services"}>Expired Services</option>
                </>
              ) : (
                <>
                  <option value="Project List">Project List</option>
                  <option value={"Expired Projects"}>Expired Projects</option>
                </>
              )}
            </select>
          </span>
        </div>
        <table className={classes.table} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className={classes.header}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            className={classes["down-arrow"]}
                            src={DownArrow}
                          />
                        ) : (
                          <img className={classes["up-arrow"]} src={UpArrow} />
                        )
                      ) : (
                        <img className={classes["transparent"]} src={UpArrow} />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length === 0 ? (
              <tr className={classes["expired-data"]}>
                <td className={classes.td}>No Data!!</td>
              </tr>
            ) : (
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    // onClick={() => {
                    //   navigate("/projectlist/viewproject", {
                    //     state: { project_id: row.original.project_id },
                    //   });
                    // }}
                    className={classes["body-row"]}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td className={classes.td} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        <div className={classes["pagination-container"]}>
          <button
            className={classes[!canPreviousPage ? "inv-goto-btn" : "goto-btn"]}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <i className="bx bx-fast-forward bx-rotate-180"></i>
          </button>
          <button
            className={classes[!canPreviousPage ? "inv-page-btn" : "page-btn"]}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          <span className={classes["page-count"]}>
            {" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <button
            className={classes[!canNextPage ? "inv-page-btn" : "page-btn"]}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
          <button
            className={classes[!canNextPage ? "inv-goto-btn" : "goto-btn"]}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <i className="bx bx-fast-forward"></i>
          </button>
        </div>
      </div>
      // </Box>
    );
  };
  return (
    <Table
      columns={column}
      data={
        isServiceSelected
          ? selectedList === "Expired Services"
            ? expiredServices
            : services
          : selectedList === "Expired Projects"
          ? expiredProjects
          : project
      }
    />
  );
};

export default ExpiryTable;
