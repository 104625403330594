import React, { useEffect, useState } from "react";
import classes from "./DomainForm.module.css";
import Toast from "../../toast/toast"
import { toast } from "react-toastify";
import axios from "axios";

// COMPONENT
import SmallBox from "../../../shared/structures/SmallBox";
import Input from "../../../shared/inputs/Input";
import FormStructure from "../../../shared/form/FormStructure";
import { formatDate } from "../../../helpers";


const DomainForm = ({ code, serviceType }) => {
  const [extensionData, setExtensionData] = useState([]);
  const [allInpVal, setAllInpVal] = useState({
    domain_name: "",
    extension: "--select--",
    no_of_years: "--select--",
    date_from: "",
    date_to: "dd-mm-yyyy",
  });
  useEffect(() => {
    const fetchExtensions = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/masterdomain`
      );
      setExtensionData(res.data);
    };
    fetchExtensions();
  }, []);

  const domainChangeHandler = (e) => {
    setAllInpVal({
      ...allInpVal,
      [e.target.name]: e.target.value,
    });
  };

  const domainSubmitHandler = async (e) => {
    e.preventDefault();
    if (
      allInpVal.domain_name.trim() == "" ||
      allInpVal.no_of_years == "--select--" ||
      allInpVal.extension == "--select--" ||
      allInpVal.date_from.trim() == "" ||
      allInpVal.date_to.trim() == "dd-mm-yyyy"
    ) {
      alert("please enter all the field");
      return;
    }
    await fetch(`${process.env.REACT_APP_BASE_URL}/service/domain`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        domain_name: allInpVal.domain_name.toLowerCase(),
        extension: allInpVal.extension.toLowerCase(),
        no_of_years: allInpVal.no_of_years.toLowerCase(),
        date_from: allInpVal.date_from.toLowerCase(),
        date_to: allInpVal.date_to.toLowerCase(),
        code: code,
        service_type: serviceType.toLowerCase(),
      }),
    });
    toast.success("Domain service added successfully.")
    setAllInpVal({
      domain_name: "",
      extension: "--select--",
      no_of_years: "--select--",
      date_from: "",
      date_to: "dd-mm-yyyy",
    });
  };

  const resetHandler = () => {
    setAllInpVal({
      domain_name: "",
      no_of_years: "--select--",
      extension: "--select--",
      date_from: "",
      date_to: "dd-mm-yyyy",
    });
  };

  // console.log(allInpVal.no_of_years, "yrs");
  // console.log(allInpVal.date_from, "allInpVal");

  useEffect(() => {
    if (allInpVal.date_from !== "" && allInpVal.no_of_years !== "--select--") {
      let date = new Date(allInpVal.date_from);
      date.setFullYear(date.getFullYear() + parseInt(allInpVal.no_of_years));
      setAllInpVal({
        ...allInpVal,date_to:formatDate(date)
      })
    }
  }, [allInpVal.date_from,allInpVal.no_of_years]);

  const astric = <sup>*</sup>;
  return (
    <FormStructure
      onSubmit={domainSubmitHandler}
      onreset={resetHandler}
      btn1Title="Reset"
      btn2Title="Save"
    >
      <SmallBox>
        <Input
          label="Domain"
          type="text"
          name="domain_name"
          placeholder="Enter Domain"
          onChange={domainChangeHandler}
          required="required"
          value={allInpVal.domain_name}
          astric={astric}
        />
        <div className={classes["inp-box"]}>
          <label className={classes.label}>Extension</label>
          <sup>*</sup>
          <select
            onChange={domainChangeHandler}
            value={allInpVal.extension}
            className={classes["drop-down"]}
            name="extension"
            required
          >
            <option value="--select--">--select--</option>
            {extensionData.map((data) => {
              return (
                <option key={data.id} value={data.extension}>
                  {data.extension}
                </option>
              );
            })}
          </select>
        </div>
      </SmallBox>
      <SmallBox>
        <label className={classes.label}>No of Years</label>
        <sup>*</sup>
        <select
          onChange={domainChangeHandler}
          value={allInpVal.no_of_years}
          className={classes.select}
          name="no_of_years"
          required
        >
          <option value="--select--">--select--</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>

        <label className={classes.label}>Date From</label>
        <sup>*</sup>
        <input
          className={classes.select}
          type="date"
          name="date_from"
          onChange={domainChangeHandler}
          required="required"
          value={allInpVal.date_from}
          astric={astric}
        />

        <label className={classes.label}>Date to</label>
        <sup>*</sup>
        <input
          className={classes.select}
          type="text"
          name="date_to"
          onChange={domainChangeHandler}
          readOnly
          value={allInpVal.date_to}
        />
      </SmallBox>
      <Toast/>
    </FormStructure>
  );
};

export default DomainForm;
