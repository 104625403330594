import React, { useState } from "react";
import classes from "./UserForm.module.css";
import axios from "axios";
// Component
import Box from "../../../shared/structures/Box";
import Form from "../../../shared/form/FormStructure";
import SmallBox from "../../../shared/structures/SmallBox";
import Input from "../../../shared/inputs/Input";
import Toast from "../../toast/toast"
import {  toast } from 'react-toastify';

const UserForm = () => {
  const [allUserValues, setAllUserValues] = useState({
    Name: "",
    username: "",
    email: "",
    branch_name: "",
    role: "--select--",
    password: "",
  });

  const userChangeHandler = (e) => {
    setAllUserValues({
      ...allUserValues,
      [e.target.name]: e.target.value,
    });
  };

  const submitUserFormHandler = async (e) => {
    e.preventDefault();
    if (
      allUserValues.Name.trim() === "" ||
      allUserValues.username.trim() === "" ||
      allUserValues.email.trim() === "" ||
      allUserValues.branch_name.trim() === "" ||
      allUserValues.role.trim() === "--select--" ||
      allUserValues.password.trim() === ""
    ) {
      return;
    }
    await axios.post(`${process.env.REACT_APP_BASE_URL}/user`, {
      Name: allUserValues.Name.toLowerCase(),
      username: allUserValues.username,
      email: allUserValues.email,
      branch_name: allUserValues.branch_name.toLowerCase(),
      role: allUserValues.role.toLowerCase(),
      password: allUserValues.password,
    });
    toast.success("User created succesfully.")
    setAllUserValues({
      Name: "",
      username: "",
      email: "",
      branch_name: "",
      role: "--select--",
      password: "",
    });
  };

  const resetHandler = () => {
    setAllUserValues({
      Name: "",
      username: "",
      email: "",
      branch_name: "",
      role: "-select--",
      password: "",
    });
  };
  const astric = <sup>*</sup>;

  return (
    <>
      <Box
        iconName="pencil-outline"
        boxTitle="Add User"
        info="User"
        parentBreadCrumb="dashboard"
      >
        <Form
          onSubmit={submitUserFormHandler}
          onreset={resetHandler}
          btn1Title="Reset"
          btn2Title="Save"
        >
          <SmallBox>
            <Input
              label="Name"
              type="text"
              name="Name"
              placeholder="Enter Name"
              onChange={userChangeHandler}
              value={allUserValues.Name}
              required="required"
              astric={astric}
            />
            <Input
              label="User Name"
              type="text"
              name="username"
              onChange={userChangeHandler}
              value={allUserValues.username}
              placeholder="Enter UserName"
              required="required"
              astric={astric}
            />
          </SmallBox>
          <SmallBox>
            <Input
              label="Email"
              type="email"
              name="email"
              onChange={userChangeHandler}
              value={allUserValues.email}
              placeholder="Enter Email"
              astric={astric}
            />
            <Input
              label="Branch Name"
              type="text"
              name="branch_name"
              onChange={userChangeHandler}
              value={allUserValues.branch_name}
              placeholder="Enter Branch Name"
              required="required"
              astric={astric}
            />
          </SmallBox>

          <SmallBox>
            <div className={classes["inp-box"]}>
              <label className={classes.label}>Role</label>
              <sup>*</sup>
              <select
                onChange={userChangeHandler}
                value={allUserValues.role}
                className={classes["drop-down"]}
                name="role"
                required
              >
                <option value="--select--">--select--</option>
                <option value="admin">Admin</option>
                <option value="visitor">Visitor</option>
              </select>
            </div>
            <Input
              label="Password"
              type="password"
              name="password"
              onChange={userChangeHandler}
              value={allUserValues.password}
              placeholder="Enter Password"
              required="required"
              astric={astric}
            />
          </SmallBox>
        </Form>
      </Box>
    </>
  );
};

export default UserForm;
