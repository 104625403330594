import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./CustomerList.module.css";
import {
  usePagination,
  useSortBy,
  useTable,
  useGlobalFilter,
} from "react-table";
import GlobalFilter from "./GlobalFilter";
import Box from "../../../shared/structures/Box";
import DownArrow from "../../../assets/down.png";
import UpArrow from "../../../assets/up.png";
import axios from "axios";

const CustomerList = () => {
  const [customerData, setCustomerData] = useState([]);
  const [customerType, setCustomerType] = useState("all");
  const navigate = useNavigate();

  const customerTableData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/customerlist`
    );
    setCustomerData(res.data);
  };
  useEffect(() => {
    customerTableData();
  }, []);
  const customerTypeHandler = (e) => {
    setCustomerType(e.target.value);
  };

  const column = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "code", // this accessor needs to match with array property name e.g => [{users : "aquib"}]
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
      },
      {
        Header: "Email ID",
        accessor: "email",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
      },
    ],
    []
  );
  const filteredCustomerData = customerData.filter((data) => {
    if (customerType === "all") {
      return customerData;
    } else if (data.customertype === customerType) {
      return data.customertype;
    }
  });

  const Table = ({ columns, data }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      nextPage,
      previousPage,
      canNextPage,
      canPreviousPage,
      pageOptions,
      gotoPage,
      pageCount,
      state,
      setGlobalFilter,
    } = useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: 0,
        },
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );

    const { globalFilter, pageIndex } = state;

    const dropdown = (
      <>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <select
          value={customerType}
          onChange={customerTypeHandler}
          className={classes["drop-down"]}
        >
          <option value="all">All</option>
          <option value="individual">Individual</option>
          <option value="company">Company</option>
        </select>
      </>
    );

    return (
      <Box
        iconName="person-outline"
        boxTitle="Customer list"
        info="Customer list"
        parentBreadCrumb="dashboard"
        dropdown={dropdown}
      >
        <div className={classes.box}>
          <div className={classes.table} {...getTableProps()}>
            <div>
              {headerGroups.map((headerGroup) => (
                <div className={classes.headers}>
                  {headerGroup.headers.map((column, id) =>
                    column.Header === "Code" ? (
                      <div
                        key={id}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {
                          <div>
                            {column.render("Header")}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <img
                                  className={classes["down-arrow"]}
                                  src={DownArrow}
                                />
                              ) : (
                                <img
                                  className={classes["up-arrow"]}
                                  src={UpArrow}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        }
                      </div>
                    ) : column.Header === "Customer Name" ? (
                      <div
                        key={id}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {
                          <div>
                            {column.render("Header")}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <img
                                  className={classes["down-arrow"]}
                                  src={DownArrow}
                                />
                              ) : (
                                <img
                                  className={classes["up-arrow"]}
                                  src={UpArrow}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        }
                      </div>
                    ) : (
                      <div key={id} {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </div>
                    )
                  )}
                </div>
              ))}
            </div>

            <div {...getTableBodyProps()}>
              {page.length === 0 ? (
                <div className={classes["expired-data"]}>
                  <div colSpan="4" className={classes.td}>NO DATA!!</div>
                </div>
              ) : (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <div
                      onClick={() => {
                        navigate("/customerlist/customerview", {
                          state: { code: row.original.code },
                        });
                      }}
                      className={classes.trow}
                      {...row.getRowProps()}
                      key={row.id}
                      code={row.original.code}
                    >
                      {row.cells.map((cell, id) => {
                        return (
                          <div
                            className={classes.td}
                            key={id}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
        <div className={classes["pagination-container"]}>
          <button
            className={classes[!canPreviousPage ? "inv-goto-btn" : "goto-btn"]}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <i className="bx bx-fast-forward bx-rotate-180"></i>
          </button>
          <button
            className={classes[!canPreviousPage ? "inv-page-btn" : "page-btn"]}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          <span className={classes["page-count"]}>
            {" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <button
            className={classes[!canNextPage ? "inv-page-btn" : "page-btn"]}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
          <button
            className={classes[!canNextPage ? "inv-goto-btn" : "goto-btn"]}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <i className="bx bx-fast-forward"></i>
          </button>
        </div>
      </Box>
    );
  };
  return <Table columns={column} data={filteredCustomerData} />;
};

export default CustomerList;
