import React from "react";
import { Link } from "react-router-dom";

import "./Box.css";

const Box = (props) => {
  const {
    iconName,
    boxTitle,
    info,
    dropdown,
   
    childBreadCrumb,
    parentBreadCrumb,
  } = props;
  return (
    <div className="box">
      <div className="box-header">
        <div>
          <ion-icon size="large" name={iconName}></ion-icon>
          <h1>{boxTitle}</h1>
        </div>
        <div>
          <button className="breadcrumb">
            <Link to={`/`}>{parentBreadCrumb}</Link>
          </button>
          <button className="breadcrumb">
            <Link to={`${childBreadCrumb}`}>{childBreadCrumb}</Link>
          </button>
        </div>
      </div>
      <div className="box-body">
        <div className="body-header">
          <span>{info}</span>
          <span>{dropdown}</span>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default Box;
