import React, { useState } from "react";
import axios from "axios";
import classes from "./DeleteBtn.module.css";
import EditModal from "../../modal/EditModal";
import FormStructure from "../../form/FormStructure";

const DeleteBtn = ({
  className,
  btnInnerText,
  id,
  ondelete,
  masterRoutes,
  deletingItem,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const deleteHandler = async (id) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/${masterRoutes}/delete/${id}`
      );
    } catch (err) {
      console.log(err);
    }
    ondelete();
    setModalShow(false);
  };
  const confirmDeleteHandler = (e) => {
    e.preventDefault();
    deleteHandler(id);
  };

  return (
    <>
      <button
        type="button"
        className={className ? className : classes["dlt-btn"]}
        onClick={() => {
          setModalShow(true);
        }}
      >
        <span>{btnInnerText}</span>
        <ion-icon name="trash-outline"></ion-icon>
      </button>
      <EditModal
        show={modalShow}
        size="small"
        title={"Confirmation"}
        onHide={() => {
          setModalShow(false);
        }}
      >
     
          <div className={classes["confirmation"]}>
            Are you sure you want to permanently <b>delete</b> this{" "}
            <b>{deletingItem}</b> ?
            <hr/>
            <button className={classes['cancel-btn']} onClick={()=>setModalShow(false)}>cancel</button>
            <button className={classes['confirm-btn']} onClick={confirmDeleteHandler}>confirm</button>
          </div>
      </EditModal>
    </>
  );
};

export default DeleteBtn;
