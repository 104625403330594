export const formatDate = (date) => {
  const dta = new Date(date);
  console.log(dta);
  var resultDate = dta.getTime() - 1 * 24 * 60 * 60 * 1000;
  dta.setTime(resultDate);

  let [month, day, year] = [
    dta.getMonth() + 1 + "",
    dta.getDate() + "",
    dta.getFullYear() + "",
  ];
  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }

  return `${year}-${month}-${day}`;
};
export const formatFullDate = (date) => {
  var d = new Date(date);
  console.log(d);
  var month = "" + (d.getMonth() + 1);

  console.log(d.getDate());
  var day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;

  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};
