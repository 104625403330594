import React, { useState, useEffect } from "react";
import classes from "./EmailForm.module.css";
import axios from "axios";
import Toast from "../../toast/toast"
import { toast } from "react-toastify";
// COMPONENTS
import SmallBox from "../../../shared/structures/SmallBox";
import Input from "../../../shared/inputs/Input";
import FormStructure from "../../../shared/form/FormStructure";
import { formatDate } from "../../../helpers";

const EmailForm = ({ code, serviceType }) => {
  const [emailData, setEmailData] = useState([]);
  const [allInpVal, setAllInpVal] = useState({
    domain_name: "",
    vendor: "--select--",
    no_of_years: "--select--",
    no_of_email: "",
    date_from: "",
    date_to: "dd-mm-yyyy",
  });

  useEffect(() => {
    const fetchHostingData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/masteremail`
        );
        setEmailData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchHostingData();
  }, []);

  const emailChangeHandler = (e) => {
    setAllInpVal({
      ...allInpVal,
      [e.target.name]: e.target.value,
    });
  };

  const emailSubmitHandler = async (e) => {
    e.preventDefault();
    if (
      allInpVal.domain_name.trim() == "" ||
      allInpVal.vendor.trim() == "--select--" ||
      allInpVal.no_of_years.trim() == "--select--" ||
      allInpVal.no_of_email.trim() == "" ||
      allInpVal.date_from.trim() == "" ||
      allInpVal.date_to.trim() == "dd-mm-yyyy"
    ) {
      alert("Please enter all the field");
      return;
    }
    await axios.post(`${process.env.REACT_APP_BASE_URL}/service/email`, {
      domain_name: allInpVal.domain_name.toLowerCase(),
      vendor: allInpVal.vendor.toLowerCase(),
      no_of_years: allInpVal.no_of_years,
      no_of_email: allInpVal.no_of_email,
      date_from: allInpVal.date_from,
      date_to: allInpVal.date_to,
      code: code,
      service_type: serviceType.toLowerCase(),
    });
    toast.success("Email service added successfully.")
    setAllInpVal({
      domain_name: "",
      vendor: "--select--",
      no_of_years: "--select--",
      no_of_email: "",
      date_from: "",
      date_to: "dd-mm-yyyy",
    });
  };
  const resetHandler = () => {
    setAllInpVal({
      domain_name: "",
      vendor: "--select--",
      no_of_years: "--select--",
      no_of_email: "",
      date_from: "",
      date_to: "dd-mm-yyyy",
    });
  };
  useEffect(() => {
    if (allInpVal.date_from !== "" && allInpVal.no_of_years !== "--select--") {
      let date = new Date(allInpVal.date_from);
      date.setFullYear(date.getFullYear() + parseInt(allInpVal.no_of_years));
      setAllInpVal({
        ...allInpVal,
        date_to: formatDate(date),
      });
    }
  }, [allInpVal.date_from, allInpVal.no_of_years]);

  const astric = <sup>*</sup>;
  return (
    <FormStructure
      onSubmit={emailSubmitHandler}
      onreset={resetHandler}
      btn1Title="Reset"
      btn2Title="Save"
    >
      <SmallBox>
        <Input
          label="Domain"
          type="text"
          name="domain_name"
          placeholder="Enter Domain"
          onChange={emailChangeHandler}
          required="required"
          value={allInpVal.domain_name}
          astric={astric}
        />
        <div className={classes["inp-box"]}>
          <label className={classes.label}>Vendor</label>
          <sup>*</sup>
          <select
            name="vendor"
            value={allInpVal.vendor}
            onChange={emailChangeHandler}
            className={classes["drop-down"]}
            required
          >
            <option value="--select--" selected>
              --select--
            </option>
            {emailData.map((data) => {
              return (
                <option key={data.id} value={data.vendor}>
                  {data.vendor}
                </option>
              );
            })}
          </select>
        </div>
      </SmallBox>
      <SmallBox>
        <div className={classes["inp-box"]}>
          <label className={classes.label}>No of Years</label>
          <sup>*</sup>
          <select
            value={allInpVal.no_of_years}
            onChange={emailChangeHandler}
            name="no_of_years"
            className={classes["drop-down"]}
            required
          >
            <option selected>--select--</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </div>
        <Input
          label="No of Email"
          type="number"
          name="no_of_email"
          placeholder="Enter no of Email"
          onChange={emailChangeHandler}
          required="required"
          value={allInpVal.no_of_email}
          astric={astric}
        />
      </SmallBox>
      <SmallBox>
        <div className={classes["inp-box"]}>
          <label className={classes.label}>Date From</label>
          <sup>*</sup>
          <input
            className={classes["drop-down"]}
            type="date"
            name="date_from"
            onChange={emailChangeHandler}
            required="required"
            value={allInpVal.date_from}
            astric={astric}
          />
        </div>
        <div className={classes["inp-box"]}>
          <label className={classes.label}>Date to</label>
          <sup>*</sup>
          <input
            className={classes["drop-down"]}
            type="text"
            name="date_to"
            onChange={emailChangeHandler}
            required="required"
            value={allInpVal.date_to}
            readOnly
            astric={astric}
          />
        </div>
      </SmallBox>
      <Toast/>
    </FormStructure>
  );
};

export default EmailForm;
