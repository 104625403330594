import React, { useState } from "react";
import classes from "./AddVendor.module.css";
import EditModal from "../../../shared/modal/EditModal";
import SmallBox from "../../../shared/structures/SmallBox";
import Input from "../../../shared/inputs/Input";
import FormStructure from "../../../shared/form/FormStructure";
import Form from "react-bootstrap/Form";
import axios from "axios";

const AddVendor = ({ onRender }) => {
  const [modalShow, setModalShow] = useState(false);
  const [vendor, setVendor] = useState("");

  const vendorChangeHandler = (e) => {
    setVendor(e.target.value);
  };

  const vendorHandler = async (e) => {
    e.preventDefault();
    await axios.post(`${process.env.REACT_APP_BASE_URL}/vendor`, {
      vendor_name: vendor,
    });
    setVendor("");
    onRender();
    setModalShow(false);
  };
  const resetHandler = () => {
    setVendor("");
  };
  const astric = <sup>*</sup>;

  return (
    <>
      <button
        onClick={() => setModalShow(true)}
        className={classes["vendor-btn"]}
      >
        + Vendor
      </button>
      {modalShow && (
        <EditModal
          show={modalShow}
          title={"Master Hosting Edit"}
          onHide={() => {
            setModalShow(false);
          }}
        >
          <FormStructure
            onSubmit={vendorHandler}
            onreset={resetHandler}
            btn1Title="Reset"
            btn2Title="Save"
          >
            <div className={classes["select-box"]}>
              <label className={classes.label}>
                Vendor <sup>*</sup>
              </label>
              <Form.Control
                type="text"
                name="vendor"
                placeholder="Enter Vendor Name"
                onChange={vendorChangeHandler}
                value={vendor}
                required
              />
            </div>
          </FormStructure>
        </EditModal>
      )}
    </>
  );
};

export default AddVendor;
