import React, { useEffect, useState } from "react";
import classes from "./CustomerEdit.module.css";
import Select from "react-select/creatable";
import Button from "react-bootstrap/Button";
import EditModal from "../../../shared/modal/EditModal";
import axios, { all } from "axios";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";

import Box from "../../../shared/structures/Box";
import FormStructure from "../../../shared/form/FormStructure";
import SmallBox from "../../../shared/structures/SmallBox";
import Input from "../../../shared/inputs/Input";
import { countryArr } from "../../../assets/Country";
import { stateArr } from "../../../assets/State";
import DropDown from "../../../shared/dropdown/DropDown";
import { toast } from "react-toastify";
import Toast from "../../toast/toast";

const CustomerEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [allCustomerVal, setAllCustomerVal] = useState({
    ...location.state.data,
  });
  const [country, setCountry] = useState(location.state.data.country);
  const [state, setState] = useState(location.state.data.state);
  const [selectedCountryName, setSelectedCountryName] = useState(
    location.state.data.country
  );
  const [selectedStateName, setSelectedStateName] = useState(
    location.state.data.state
  );
  const [countrySearchVal, setCountrySearchVal] = useState(
    location.state.data.country
  );
  const [stateSearchVal, setStateSearchVal] = useState(
    location.state.data.state
  );
  const [isGstExempted, setIsGstExempted] = useState(false);
  const customerChangeHandler = (e) => {
    setAllCustomerVal({
      ...allCustomerVal,
      [e.target.name]: e.target.value,
    });
  };
  const getSelectedCountryName = (value) => {
    setCountry(value);
  };
  const getSelectedStateName = (value) => {
    setState(value);
  };

  const data = stateArr.filter((data) => data.country_name === country);

  let gstRegex = new RegExp(
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
  );
  let regPan = new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/);

  const submitCustomerFormHandler = async (e) => {
    e.preventDefault();
    if (
      allCustomerVal.GST_No &&
      allCustomerVal.GST_No !== "exempted" &&
      !gstRegex.test(allCustomerVal.GST_No)
    ) {
      alert("Enter Valid Gst number");
      return;
    }
    if (allCustomerVal.PAN_No && !regPan.test(allCustomerVal.PAN_No)) {
      alert("Enter Valid PAN number");
      return;
    }
    if (
      allCustomerVal.customer_name.trim() == "" ||
      allCustomerVal.zip_code.trim() == "" ||
      allCustomerVal.city.trim() == "" ||
      state.trim() == "" ||
      country.trim() == "" ||
      allCustomerVal.mobile.trim() == "" ||
      allCustomerVal.email.trim() == "" ||
      allCustomerVal.address1.trim() == "" ||
      allCustomerVal.address2.trim() == ""
    ) {
      alert("Please Enter All Required Field");
      return;
    }
    await axios.put(
      `${process.env.REACT_APP_BASE_URL}/customer/${allCustomerVal.code}`,
      {
        code: allCustomerVal.code,
        customertype: allCustomerVal.customertype,
        customer_name: allCustomerVal.customer_name,
        email: allCustomerVal.email,
        mobile: allCustomerVal.mobile,
        tel_no: allCustomerVal.tel_no,
        alternate_no: allCustomerVal.alternate_no,
        address1: allCustomerVal.address1,
        address2: allCustomerVal.address2,
        city: allCustomerVal.city,
        zip_code: allCustomerVal.zip_code,
        state: state,
        country: country,
        pan_no: allCustomerVal.pan_no,
        gst_no: isGstExempted ? "exempted" : allCustomerVal.gst_no,
      }
    );
    toast.success("Customer details updated succesfully.");
    setIsGstExempted(false);
    setTimeout(() => {
      navigate("/customerlist");
    }, 2000);
  };

  const resetHandler = () => {
    setAllCustomerVal(...location.state.data);
  };
  const exemptHandler = () => {
    setIsGstExempted((prev) => {
      return !prev;
    });
  };
  const astric = <sup>*</sup>;
  return (
    <>
      <Box
        iconName="pencil-outline"
        boxTitle="Add Customer"
        info="Customer Info"
        parentBreadCrumb="dashboard"
        childBreadCrumb="/customerList"
      >
        <FormStructure
          onSubmit={submitCustomerFormHandler}
          onreset={resetHandler}
          btn1Title="Reset"
          btn2Title="Save"
        >
          <div className={classes["customer-type"]}>
            <label>Customer type</label>
            <sup>*</sup>
            <select
              onChange={customerChangeHandler}
              className={classes["select-option"]}
              name="customertype"
              value={allCustomerVal.customertype}
            >
              <option value="select">--select--</option>
              <option value="company">Company</option>
              <option value="individual">Individual</option>
            </select>
          </div>

          <>
            {allCustomerVal.customertype === "select" ? (
              ""
            ) : (
              <>
                <SmallBox>
                  <Input
                    label="Code"
                    type="text"
                    name="code"
                    placeholder="Enter Code"
                    onChange={customerChangeHandler}
                    value={allCustomerVal.code}
                    required="required"
                    astric={astric}
                    readOnly="readonly"
                  />
                  <Input
                    label={
                      allCustomerVal.customerType === "individual"
                        ? "Name"
                        : "Company name"
                    }
                    type="text"
                    name="customer_name"
                    placeholder={
                      allCustomerVal.customerType === "individual"
                        ? "Enter Name"
                        : "Enter Company Name"
                    }
                    onChange={customerChangeHandler}
                    value={allCustomerVal.customer_name}
                    required="required"
                    astric={astric}
                  />
                </SmallBox>
                <SmallBox>
                  <Input
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="Enter Email"
                    onChange={customerChangeHandler}
                    value={allCustomerVal.email}
                    required="required"
                    astric={astric}
                  />
                  <Input
                    label="Mobile no."
                    type="number"
                    name="mobile"
                    placeholder="Enter Mobile number"
                    onChange={customerChangeHandler}
                    value={allCustomerVal.mobile}
                    required="required"
                    astric={astric}
                  />
                </SmallBox>

                <SmallBox>
                  <Input
                    label="Alternate no."
                    type="number"
                    name="alternate_no"
                    placeholder="Enter Alternate Number"
                    onChange={customerChangeHandler}
                    value={allCustomerVal.alternate_no}
                  />
                  <Input
                    label="Telephone No."
                    type="number"
                    name="tel_no"
                    placeholder="Enter Landline number"
                    onChange={customerChangeHandler}
                    value={allCustomerVal.tel_no}
                  />
                </SmallBox>
                <SmallBox>
                  <Input
                    label="Zip Code"
                    type="number"
                    name="zip_code"
                    placeholder="Enter Zip Code"
                    onChange={customerChangeHandler}
                    value={allCustomerVal.zip_code}
                    required="required"
                    astric={astric}
                  />
                  <DropDown
                    label="Country"
                    optionOutput={"value"}
                    optionVal={"value"}
                    optionArr={countryArr}
                    getVal={getSelectedCountryName}
                    onSearch={countrySearchVal}
                    setSearch={setCountrySearchVal}
                    onSelect={selectedCountryName}
                    setSelected={setSelectedCountryName}
                  />
                </SmallBox>
                <SmallBox>
                  <DropDown
                    label="State"
                    optionOutput={"value"}
                    optionVal={"value"}
                    optionArr={data}
                    getVal={getSelectedStateName}
                    onSearch={stateSearchVal}
                    setSearch={setStateSearchVal}
                    onSelect={selectedStateName}
                    setSelected={setSelectedStateName}
                  />
                  <Input
                    label="City"
                    type="text"
                    name="city"
                    placeholder="Enter City"
                    onChange={customerChangeHandler}
                    value={allCustomerVal.city}
                    required="required"
                    astric={astric}
                  />
                </SmallBox>
                <SmallBox>
                  <div className={classes["text-box"]}>
                    <label className={classes["text-area-label"]}>
                      Address 1
                    </label>
                    <sup>*</sup>
                    <textarea
                      name="address1"
                      className={classes["text-area"]}
                      placeholder="Enter Address"
                      value={allCustomerVal.address1}
                      onChange={customerChangeHandler}
                      rows="2"
                      required
                    />
                  </div>
                </SmallBox>
                <SmallBox>
                  <div className={classes["text-box"]}>
                    <label className={classes["text-area-label"]}>
                      Address 2
                    </label>
                    <sup>*</sup>
                    <textarea
                      name="address2"
                      className={classes["text-area"]}
                      placeholder="Enter Address"
                      value={allCustomerVal.address2}
                      onChange={customerChangeHandler}
                      rows="2"
                      required
                    />
                  </div>
                </SmallBox>

                <SmallBox>
                  <div className={classes["inp-box"]}>
                    <label className={classes["inp-label"]}>PAN No.</label>
                    <input
                      className={classes["inp"]}
                      type="text"
                      name="pan_no"
                      placeholder="Enter Pan Number"
                      onChange={customerChangeHandler}
                      value={allCustomerVal.pan_no}
                    />
                  </div>
                  <div className={classes["inp-box"]}>
                    <label
                      className={
                        classes[isGstExempted ? "label-disable" : "inp-label"]
                      }
                    >
                      GST no.
                    </label>
                    <input
                      className={classes["inp"]}
                      type="text"
                      name="gst_no"
                      readOnly={isGstExempted}
                      placeholder="Enter Gst Number"
                      onChange={customerChangeHandler}
                      value={allCustomerVal.gst_no}
                    />
                  </div>
                </SmallBox>
                <div className={classes["check-container"]}>
                  <label
                    className={classes["check-bx-label"]}
                    htmlFor="Exempted"
                  >
                    GST Exempted :
                  </label>
                  <Form.Check
                    onChange={exemptHandler}
                    type="switch"
                    className={classes["check-inp"]}
                  />
                </div>
              </>
            )}
          </>
        </FormStructure>
        <Toast />
      </Box>
    </>
  );
};

export default CustomerEdit;
