import React, { useState, useEffect } from "react";
import classes from "./MasterSslForm.module.css";
import axios from "axios";
// Component
import Box from "../../../shared/structures/Box";
import FormStructure from "../../../shared/form/FormStructure";
import Form from "react-bootstrap/Form";
import SmallBox from "../../../shared/structures/SmallBox";
import Input from "../../../shared/inputs/Input";
import DeleteBtn from "../../../shared/buttons/deleteButton/DeleteBtn";
import EditBtn from "../../../shared/buttons/editButton/EditBtn";
import EditModal from "../../../shared/modal/EditModal";
import AddVendor from "../addvendor/AddVendor";
import {  toast } from 'react-toastify';
import Toast from "../../toast/toast";

const MasterSslForm = () => {
  const [allMasterSslVal, setAllMasterSslVal] = useState({
    vendor: "",
    plan_name: "",
    price: "",
  });
  const [allMasterEditedSsl, setAllMasterEditedSsl] = useState({
    editVendor: "",
    editplan_name: "",
    editPrice: "",
    id:""
  });
  const [allMasterEffectiveVal, setAllMasterEffectiveVal] = useState({
    vendor: "",
    plan_name: "",
    old_price: "",
    price: "",
    effective_date: "",
    id:""
  });

  const [tableData, setTableData] = useState([]);
  const [effectiveModalShow, setEffectiveModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [reFetch, setReFetch] = useState(false);
  let i = 1;

  const MasterSslChangeHandler = (e) => {
    setAllMasterSslVal({
      ...allMasterSslVal,
      [e.target.name]: e.target.value,
    });
  };

  const masterEditSslChangeHandler = (e) => {
    setAllMasterEditedSsl({
      ...allMasterEditedSsl,
      [e.target.name]: e.target.value,
    });
  };
  const masterEffectiveSslChangeHandler = (e) => {
    setAllMasterEffectiveVal({
      ...allMasterEffectiveVal,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/masterssl`
        );
        setTableData(res.data);
      } catch (error) {
        throw new Error("something went wrong", error);
      }
    };
    fetchTableData();
  }, [reFetch]);
  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/vendor`);
        setVendorData(res.data);
      } catch (error) {
        throw new Error("something went wrong", error);
      }
    };
    fetchVendors();
  }, [reFetch]);

  const submitMasterSslFormHandler = async (e) => {
    e.preventDefault();
    await axios.post(`${process.env.REACT_APP_BASE_URL}/masterssl`, {
      vendor: allMasterSslVal.vendor.toLowerCase(),
      plan_name: allMasterSslVal.plan_name.toLowerCase(),
      price: allMasterSslVal.price,
    });
    setAllMasterSslVal({
      vendor: "--select--",
      plan_name: "",
      price: "",
    });
    toast.success("SSL submited successfully")
    setReFetch(!reFetch);
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    await axios.put(
      `${process.env.REACT_APP_BASE_URL}/masterssl/update/${allMasterEditedSsl.id}`,
      {
        vendor: allMasterEditedSsl.editVendor.toLowerCase(),
        plan_name: allMasterEditedSsl.editplan_name.toLowerCase(),
        price: allMasterEditedSsl.editPrice,
      }
    );
    setModalShow(false);
    setReFetch(!reFetch);
    setAllMasterEditedSsl({
      editVendor: "",
      editplan_name: "",
      editPrice: "",
    });
    toast.success("SSL update successfully.")
  };

  const deleteHandler = () => {
    toast.error("SSL deleted successfully!!!")
    setReFetch(!reFetch);
  };

  const editHandler = (data) => {
    setAllMasterEditedSsl({
      editVendor: data.vendor,
      editplan_name: data.plan_name,
      editPrice: data.price,
      id:data.id
    });
    setModalShow(true);
  };

  const effectiveEditHandler = (data) => {
    setAllMasterEffectiveVal({
      vendor: data.vendor,
      plan_name: data.plan_name,
      old_price: data.price,
      id:data.id,
      price: "",
      effective_date: "",
    });
    setEffectiveModalShow(true);
  };
  const effectiveUpdateHandler = async (e) => {
    e.preventDefault();
    await axios.put(
      `${process.env.REACT_APP_BASE_URL}/masterssl/update/${allMasterEffectiveVal.id}`,
      {
        vendor: allMasterEffectiveVal.vendor.toLowerCase(),
        plan_name: allMasterEffectiveVal.plan_name.toLowerCase(),
        price: allMasterEffectiveVal.price,
        effective_date: allMasterEffectiveVal.effective_date,
      }
    );
    setEffectiveModalShow(false);
    setReFetch(!reFetch);
    setAllMasterEffectiveVal({
      vendor: "",
      plan_name: "",
      old_price: "",
      price: "",
      effective_date: "",
    });
  };

  const renderHandler = () => {
    setReFetch(!reFetch);
  };

  const resetHandler = () => {
    setAllMasterSslVal({
      vendor: "--select--",
      plan_name: "",
      price: "",
    });
  };
  const astric = <sup>*</sup>;

  return (
    <>
      <Box
        parentBreadCrumb="dashboard"
        iconName="pencil-outline"
        boxTitle="Add SSL"
        info="Master SSL Info"
      >
        <FormStructure
          onSubmit={submitMasterSslFormHandler}
          onreset={resetHandler}
          btn1Title="Reset"
          btn2Title="Save"
        >
          <SmallBox>
            <div className={classes["select-box"]}>
              <div>
                <label className={classes.label}>Vendor </label>
                <sup>*</sup>
              </div>
              <div>
                <Form.Select
                  className={classes.select}
                  name="vendor"
                  value={allMasterSslVal.vendor}
                  onChange={MasterSslChangeHandler}
                  required
                  aria-label="Default select example"
                >
                  <option value="--select--">--select--</option>
                  {vendorData.map((data) => {
                    return (
                      <option key={data.id} value={data.vendor_name}>
                        {data.vendor_name}
                      </option>
                    );
                  })}
                </Form.Select>
                <AddVendor onRender={renderHandler} />
              </div>
            </div>
          </SmallBox>
          <SmallBox>
            <div className={classes["inp-box"]}>
              <Input
                label="Name"
                type="text"
                name="plan_name"
                onChange={MasterSslChangeHandler}
                value={allMasterSslVal.plan_name}
                placeholder="Enter Name"
                required="required"
                astric={astric}
              />
            </div>
          </SmallBox>
          <SmallBox>
            <div className={classes["inp-box"]}>
              <Input
                label="Price"
                type="Number"
                name="price"
                onChange={MasterSslChangeHandler}
                value={allMasterSslVal.price}
                placeholder="Enter Price"
                required="required"
                astric={astric}
              />
            </div>
          </SmallBox>
        </FormStructure>
      </Box>
      <table className={classes["table"]}>
        <thead className={classes.thead}>
          <tr>
            <th>Sr.no</th>
            <th>Vendor</th>
            <th>Name</th>
            <th>Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className={classes.tbody}>
          {tableData.length === 0 ? (
            <tr>
              <td className={classes["no-data"]} colSpan={5}>
                NO DATA
              </td>
            </tr>
          ) : (
            tableData.map((data) => (
              <tr className={classes.trow} key={data.id}>
                <td>{i++}</td>
                <td>{data.vendor}</td>
                <td>{data.plan_name}</td>
                <td>
                  &#8377;
                  {data.price}
                </td>
                <td>
                  <EditBtn
                    onClick={() => {
                      editHandler(data);
                    }}
                  />
                  {modalShow && (
                    <EditModal
                      show={modalShow}
                      title={"Master SSL Edit"}
                      onHide={() => {
                        setModalShow(false);
                      }}
                    >
                      <FormStructure
                        onSubmit={updateHandler}
                        onreset={resetHandler}
                        btn1Title="Reset"
                        btn2Title="Save"
                      >
                        <SmallBox>
                          <Input
                            label="Vendor"
                            type="text"
                            name="editVendor"
                            placeholder="Enter Vendor"
                            onChange={masterEditSslChangeHandler}
                            required="required"
                            value={allMasterEditedSsl.editVendor}
                            astric={astric}
                          />
                          <Input
                            label="Name"
                            type="text"
                            name="editplan_name"
                            onChange={masterEditSslChangeHandler}
                            value={allMasterEditedSsl.editplan_name}
                            placeholder="Enter Name"
                            required="required"
                            astric={astric}
                          />
                        </SmallBox>
                        <SmallBox>
                          <Input
                            label="Price"
                            type="number"
                            name="editPrice"
                            onChange={masterEditSslChangeHandler}
                            value={allMasterEditedSsl.editPrice}
                            placeholder="Enter Price"
                            required="required"
                            astric={astric}
                          />
                        </SmallBox>
                      </FormStructure>
                    </EditModal>
                  )}
                  <DeleteBtn
                    id={data.id}
                    masterRoutes={"masterssl"}
                    ondelete={deleteHandler}
                  />
                  <button
                    onClick={() => effectiveEditHandler(data)}
                    className={classes["effective-button"]}
                  >
                    <ion-icon name="calendar-outline"></ion-icon>
                  </button>
                  {effectiveModalShow && (
                    <EditModal
                      show={effectiveModalShow}
                      title={"Update With Effective Date"}
                      onHide={() => {
                        setEffectiveModalShow(false);
                      }}
                    >
                      <FormStructure
                        onSubmit={effectiveUpdateHandler}
                        onreset={resetHandler}
                        btn1Title="Reset"
                        btn2Title="Save"
                      >
                        <SmallBox>
                          <Input
                            label="Vendor"
                            type="text"
                            name="vendor"
                            placeholder="Enter Vendor"
                            onChange={masterEffectiveSslChangeHandler}
                            required="required"
                            value={allMasterEffectiveVal.vendor}
                            astric={astric}
                          />
                          <Input
                            label="Name"
                            type="text"
                            name="plan_name"
                            onChange={masterEffectiveSslChangeHandler}
                            value={allMasterEffectiveVal.plan_name}
                            placeholder="Enter Name"
                            required="required"
                            astric={astric}
                          />
                        </SmallBox>
                        <SmallBox>
                          <Input
                            label="Old Price"
                            type="number"
                            name="editPrice"
                            onChange={masterEffectiveSslChangeHandler}
                            value={allMasterEffectiveVal.old_price}
                            placeholder="Enter Price"
                            required="required"
                            astric={astric}
                            readOnly={"readonly"}
                          />
                          <Input
                            label="New Price"
                            type="text"
                            name="price"
                            placeholder="Enter New price"
                            onChange={masterEffectiveSslChangeHandler}
                            required="required"
                            value={allMasterEffectiveVal.price}
                            astric={astric}
                          />
                        </SmallBox>
                        <SmallBox>
                          <Input
                            label="Effective Date"
                            type="date"
                            name="effective_date"
                            onChange={masterEffectiveSslChangeHandler}
                            value={allMasterEffectiveVal.effective_date}
                            placeholder="Enter Effective Date"
                            required="required"
                            astric={astric}
                          />
                        </SmallBox>
                      </FormStructure>
                    </EditModal>
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
        <Toast/>
      </table>
    </>
  );
};

export default MasterSslForm;
