import React, { useState, useEffect } from "react";
import Box from "../../../shared/structures/Box";
import FormStructure from "../../../shared/form/FormStructure";
import SmallBox from "../../../shared/structures/SmallBox";
import Input from "../../../shared/inputs/Input";
import classes from "./AddProject.module.css";
import axios from "axios";
import DropDown from "../../../shared/dropdown/DropDown";
import Toast from "../../toast/toast"
import { toast } from "react-toastify";

const AddProject = () => {
  const [projectType, setProjectType] = useState([]);
  const [customerName, setCustomerName] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [code, setCode] = useState("");
  const [allProjectVal, setAllProjectVal] = useState({
    project_type: "Select...",
    project_name: "",
    amount: "",
    start_date: "",
    end_date: "",
    project_description: "",
  });

  const [allModuleVal, setAllModuleVal] = useState([
    {
      module_name: "",
      module_description: "",
    },
  ]);

  const projectInpChangeHandler = (e) => {
    setAllProjectVal({
      ...allProjectVal,
      [e.target.name]: e.target.value,
    });
  };
  const moduleChangeHandler = (e, index) => {
    const newArr = [...allModuleVal];
    newArr[index] = {
      ...newArr[index],
      [e.target.name]: e.target.value,
    };
    setAllModuleVal(newArr);
  };
  const getSelectedCustomerName = (value) => {
    setCode(value);
  };

  useEffect(() => {
    const fetchProjectType = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/projecttype`
        );
        setProjectType(res.data);
      } catch (error) {
        throw new Error("something went wrong", error);
      }
    };
    fetchProjectType();
    const fetchCustomerName = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/customer`
        );
        setCustomerName(res.data);
      } catch (error) {
        throw new Error("something went wrong", error);
      }
    };
    fetchCustomerName();
  }, []);

  const submitProjectHandler = async (e) => {
    if (
      code !== "" ||
      allProjectVal.project_type !== "Select..." ||
      allProjectVal.start_date !== "" ||
      allProjectVal.end_date !== "" ||
      allProjectVal.project_name.trim() !== "" ||
      allProjectVal.amount.trim() !== "" ||
      allProjectVal.project_description.trim() !== ""
    ) {
      e.preventDefault();
      await axios.post(`${process.env.REACT_APP_BASE_URL}/project`, [
        {
          code: code,
          project_type: allProjectVal.project_type,
          project_name: allProjectVal.project_name,
          amount: allProjectVal.amount,
          start_date: allProjectVal.start_date,
          end_date: allProjectVal.end_date,
          project_description: allProjectVal.project_description,
        },
        [...allModuleVal],
      ]);

      setAllModuleVal([
        {
          module_name: "",
          module_description: "",
        },
      ]);
      toast.success('Project added successfully')
      setAllProjectVal({
        project_type: "Select...",
        project_name: "",
        amount: "",
        start_date: "",
        end_date: "",
        project_description: "",
      });
      setCode("");
      setSearchVal("");
      setSelectedValue("");
    }
    
  };
  const deleteModuleHandler = (index) => {
    const filtered = allModuleVal.filter((data, id) => id !== index);
    setAllModuleVal([...filtered]);
  };

  const resetHandler = () => {
    setAllProjectVal({
      project_type: "Select...",
      project_name: "",
      amount: "",
      start_date: "",
      end_date: "",
      project_description: "",
    });
    setAllModuleVal([
      {
        module_name: "",
        module_description: "",
      },
    ]);

    setCode("");
  };
  const astric = <sup>*</sup>;
  return (
    <Box
      iconName="pencil-outline"
      boxTitle="Add Project"
      info="Add Project Details"
      parentBreadCrumb="dashboard"
    >
      <FormStructure
        onSubmit={submitProjectHandler}
        onreset={resetHandler}
        btn1Title="Reset"
        btn2Title="Save"
      >
        <SmallBox>
          <DropDown
            optionOutput={"customer_name"}
            optionVal={"code"}
            optionArr={customerName}
            label="Customer Name"
            getVal={getSelectedCustomerName}
            onSearch={searchVal}
            setSearch={setSearchVal}
            onSelect={selectedValue}
            setSelected={setSelectedValue}
          />
          <div className={classes["select-box"]}>
            <label className={classes.label}>Project Type</label>
            <sup>*</sup>
            <select
              onChange={projectInpChangeHandler}
              className={classes["select-option"]}
              value={allProjectVal.project_type}
              name="project_type"
            >
              <option value="--select--">Select...</option>
              {projectType.map((el) => {
                return (
                  <option key={el.id} value={el.project_type}>
                    {el.project_type.toUpperCase()}
                  </option>
                );
              })}
            </select>
          </div>
        </SmallBox>
        <SmallBox>
          <Input
            label="Project Name"
            type="text"
            name="project_name"
            placeholder="Enter Project Name"
            onChange={projectInpChangeHandler}
            value={allProjectVal.project_name}
            required="required"
            astric={astric}
          />
          <Input
            label="Amount"
            type="number"
            name="amount"
            placeholder="Enter Amount"
            onChange={projectInpChangeHandler}
            value={allProjectVal.amount}
            required="required"
            astric={astric}
          />
        </SmallBox>
        <SmallBox>
          <div className={classes["inp-box"]}>
            <label className={classes.label}>Date From</label>
            <sup>*</sup>
            <input
              className={classes["drop-down"]}
              type="date"
              name="start_date"
              onChange={projectInpChangeHandler}
              required="required"
              value={allProjectVal.start_date}
              astric={astric}
            />
          </div>
          <div className={classes["inp-box"]}>
            <label className={classes.label}>Date To</label>
            <sup>*</sup>
            <input
              className={classes["drop-down"]}
              type="date"
              name="end_date"
              onChange={projectInpChangeHandler}
              required="required"
              value={allProjectVal.end_date}
              astric={astric}
            />
          </div>
        </SmallBox>
        <SmallBox>
          <div className={classes["text-box"]}>
            <label className={classes["text-area-label"]}>
              Project Description
            </label>
            <sup>*</sup>
            <textarea
              name="project_description"
              className={classes["text-area"]}
              placeholder="Enter Project Description"
              onChange={projectInpChangeHandler}
              value={allProjectVal.project_description}
              rows="3"
              required
            />
          </div>
        </SmallBox>
        {allModuleVal.map((module, index) => (
          <div className={classes["module-container"]}>
            <div className={classes["module-header"]}>
              <div>Module {index + 1} </div>
              <span
                onClick={() => deleteModuleHandler(index)}
                className={classes["cross-btn"]}
              >
                X
              </span>
            </div>
            <SmallBox>
              <Input
                label="Module Name "
                type="text"
                name="module_name"
                placeholder="Enter Module Name"
                onChange={(e) => moduleChangeHandler(e, index)}
                value={allModuleVal[index].module_name}
                required="required"
                astric={astric}
              />
            </SmallBox>
            <SmallBox>
              <div className={classes["text-box"]}>
                <label className={classes["module-label"]}>
                  Module Description
                </label>
                <sup>*</sup>
                <textarea
                  name="module_description"
                  className={classes["text-area"]}
                  placeholder="Enter Module Description"
                  onChange={(e) => moduleChangeHandler(e, index)}
                  value={allModuleVal[index].module_description}
                  rows="3"
                  required
                />
              </div>
            </SmallBox>
          </div>
        ))}
        <div className={classes["btn-container"]}>
          <button
            type="button"
            onClick={() =>
              setAllModuleVal([
                ...allModuleVal,
                { module_name: "", module_description: "" },
              ])
            }
            className={classes["add-module"]}
          >
            + Module
          </button>
        </div>
      </FormStructure>
      <Toast/>
    </Box>
  );
};

export default AddProject;
